<template>
  <div>

    <router-view></router-view>

    <van-tabbar route>
        <van-tabbar-item icon="home-o" v-if="adminFlag == 1" name="ccc" to="/tabbar/adminHome">主页</van-tabbar-item>
        <van-tabbar-item icon="home-o" v-if="adminFlag == 0" name="aaa" to="/tabbar/home">主页</van-tabbar-item>
        <van-tabbar-item icon="friends-o"  name="bbb" to="/tabbar/person">我的</van-tabbar-item>

    </van-tabbar>

  </div>
</template>

<script>
export default {
  name: "Tabbar",
  data(){
    return{
        adminFlag :localStorage.getItem("adminFlag"),
        active:"bbb",
    }
  }
};

</script>

<style></style>
