import {
  confirmBill,
  refuseBill,
  cancelBill,
  completedBill,
  confirmExtensionBill,
  applyCompletedBill,
} from "@/api";
import MyCustomError from "../MyCustomError.js";
const state = {
  count: 0,
};

const mutations = {};

const actions = {
  async confirmBill(c, data) {
    const result = await confirmBill(data);
    if (result.code == 200) {
      return result;
    } else {
      return Promise.reject(new MyCustomError(result.message, result.code));
    }
  },

  async refuseBill(c, data) {
    const result = await refuseBill(data);
    if (result.code == 200) {
      return result;
    } else {
      return Promise.reject(new MyCustomError(result.message, result.code));
    }
  },

  async cancelBill(c, data) {
    const result = await cancelBill(data);
    if (result.code == 200) {
      return result;
    } else {
      return Promise.reject(new MyCustomError(result.message, result.code));
    }
  },
  async completedBill(c, data) {
    const result = await completedBill(data);
    if (result.code == 200) {
      return result;
    } else {
      return Promise.reject(new MyCustomError(result.message, result.code));
    }
  },
  async confirmExtensionBill(c, data) {
    const result = await confirmExtensionBill(data);
    if (result.code == 200) {
      return result;
    } else {
      return Promise.reject(new MyCustomError(result.message, result.code));
    }
  },
  async applyCompletedBill(c, data) {
    const result = await applyCompletedBill(data);
    if (result.code == 200) {
      return result;
    } else {
      return Promise.reject(new MyCustomError(result.message, result.code));
    }
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
