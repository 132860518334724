<template>
  <div class="aaa">
    <div class="debt-note">
      <h1 class="title">欠条</h1>
      <div class="tiao"></div>
      <div class="debtor-info">
        <p>
          {{ borrowerName }}（居民身份证号：{{ borrowerIdCard }}）欠
          {{ lenderName }} （居民身份证号：{{ lenderIdCard }}）人民币{{
            amountStr
          }}元整（￥{{ amount }}） ，欠款期限为{{ startDate }}至{{
            expirationDate
          }}，承诺按年化利率{{
            annualizedInterestRate
          }}%计算支付利息，于到期日偿还本息。
        </p>
      </div>
      <div class="debtor-info">
        <p v-if="usageOfLoan == 1">欠条原因：线下借款</p>
        <p v-if="usageOfLoan == 2">欠条原因：其它</p>
      </div>
      <div class="creditor-info">
        <p>债权人：{{ lenderName }}</p>
        <p>居民身份证号：{{ lenderIdCard }}</p>
        <p>手机号：{{ lenderPhoneNumber }}</p>
      </div>
      <div class="debt-details">
        <p>债务人：{{ borrowerName }}</p>
        <p>居民身份证号：{{ borrowerIdCard }}</p>
        <p>手机号：{{ borrowerPhoneNumber }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Contract",
  data() {
    return {
      borrowerName: "",
      borrowerPhoneNumber: "",
      borrowerIdCard: "",
      lenderName: "",
      lenderPhoneNumber: "",
      lenderIdCard: "",
      amount: 0,
      amountStr: "",
      startDate: "",
      expirationDate: "",
      annualizedInterestRate: 0,
      usageOfLoan: "",
      numToChinese: {
        0: "零",
        1: "壹",
        2: "贰",
        3: "叁",
        4: "肆",
        5: "伍",
        6: "陆",
        7: "柒",
        8: "捌",
        9: "玖",
      },
      units: ["", "拾", "佰", "仟"],
      bigUnits: ["", "万", "亿"],
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const param = {
        billCode: this.$route.query.billCode,
      };
      try {
        const result = await this.$store.dispatch("getBillContract", param);
        if (!result.data) {
          this.$router.push("/error");
        }
        this.borrowerName = result.data.borrowerName;
        this.borrowerPhoneNumber = result.data.borrowerPhoneNumber;
        this.borrowerIdCard = result.data.borrowerIdCard;
        this.lenderName = result.data.lenderName;
        this.lenderPhoneNumber = result.data.lenderPhoneNumber;
        this.lenderIdCard = result.data.lenderIdCard;
        this.startDate = this.formatDate(result.data.startDate);
        this.expirationDate = this.formatDate(result.data.expirationDate);
        this.annualizedInterestRate = result.data.annualizedInterestRate;
        this.usageOfLoan = result.data.usageOfLoan;
        this.amount = result.data.amount;
        this.amountStr = this.convertToChinese(this.amount);
      } catch (error) {
        this.$refs.autoCloseDialog.openErrorDialog(error);
      }
    },
    formatDate(inputDate) {
      // 将输入字符串按-分割成数组
      const [year, month, day] = inputDate.split("-");

      // 组装成YYYY年MM月DD日格式
      const formattedDate = `${year}年${month}月${day}日`;

      return formattedDate;
    },
    convertToChinese(amount) {
      if (this.amount <= 0 || this.amount > 999999999) {
        return;
      }

      let numStr = String(amount);
      let len = numStr.length;
      let result = "";
      let zeroCount = 0;

      for (let i = 0; i < len; i++) {
        let num = Number(numStr[i]);
        let unitIndex = len - i - 1;
        let unit = this.units[unitIndex % 4];
        let bigUnit = this.bigUnits[Math.floor(unitIndex / 4)];

        if (num === 0) {
          zeroCount++;
        } else {
          if (zeroCount > 0) {
            result += this.numToChinese[0];
          }
          zeroCount = 0;
          result += this.numToChinese[num] + unit;
        }

        if (unitIndex % 4 === 0 && zeroCount < 4) {
          result += bigUnit;
        }
      }

      // 清理结果字符串，例如去掉多余的"零"
      result = result
        .replace(/零(仟|佰|拾|角)/g, "零")
        .replace(/(零)+/g, "零")
        .replace(/零(万|亿|元)/g, "$1")
        .replace(/(亿)万|壹(拾)/g, "$1$2")
        .replace(/^元零?|零分/g, "")
        .replace(/元$/g, "元整");

      return result;
    },
  },
};
</script>
<style>
.debt-note {
  background-color: #ffffff;
  font-family: Arial, sans-serif;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.debtor-info,
.creditor-info,
.debt-details {
  margin-bottom: 20px;
}

h1 {
  text-align: center;
  color: #333;
}

p {
  margin: 5px 0;
  color: #666;
}
.aaa {
  background-color: #f0f0f0; /* 选择一个你喜欢的灰色调 */
  padding: 30px 30px;
  height:100vh;
}
.tiao {
  height: 3px;
  margin: 20px 10px;
  background-color: dodgerblue;
}
</style>
