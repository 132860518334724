import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);  

import iou from "./iou"
import home from "./home"
import login from './login'
import whiteList from './whiteList'
import loanContent from './loanContent'

export default new Vuex.Store({
    modules: {
        home,
        iou,
        login,
        whiteList,
        loanContent
    }
})