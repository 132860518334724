<template>
  <div class="set-con">
    <auto-close-dialog ref="autoCloseDialog" />
    <van-cell is-link title="修改交易密码" to="/changePwd" />
    <van-cell is-link title="用户协议" :url="UserAgreement"/>
    <van-cell is-link title="隐私政策" :url="PrivacyPolicy" />

    <div class="cell-swrap cell-fixed">
      <van-button round block class="btn" @click="systemLogout"
        >退出登录</van-button
      >

      <!-- <div class="label">退出登录</div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Setting",
  data() {
    return {
      UserAgreement:
        this.$store.state.login.domainName +"/%E7%94%A8%E6%88%B7%E5%8D%8F%E8%AE%AE.html",
      PrivacyPolicy:
        this.$store.state.login.domainName +"/%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.html",
    };
  },
  methods: {
    async systemLogout() {
      try {
        await this.$store.dispatch("systemLogout");
        this.$refs.autoCloseDialog.openDialog("退出成功");
        setTimeout(() => {
          localStorage.removeItem("token");
          this.$router.push("/login");
        }, 1000);
      } catch (error) {
        this.$refs.autoCloseDialog.openErrorDialog(error);
      }
    },
  },
};
</script>
<style scoped lang="less">
@import url("@/assets/Less/index.less");
@import url("index.less");
</style>
