<template>
  <div class="my-person">
    <auto-close-dialog ref="autoCloseDialog" />
    <!-- 弹窗 -->
    <van-dialog
      v-model="show"
      show-cancel-button
      :closeOnClickOverlay="true"
      class="Dialog"
      title="请选择头像"
      @confirm="confirm"
      @close="cancel"
    >
      <van-row>
        <van-col
          span="12"
          v-for="img in images"
          :key="img.value"
          class="imageWapper"
          @click="Selectedimage(img)"
        >
          <van-image
            width="100"
            height="100"
            :src="img.url"
            class="component-wrapper"
          >
            <div v-if="img.isMasked" class="overlay"></div>
          </van-image>
        </van-col>
      </van-row>
    </van-dialog>

    <div class="header">
      <div class="header-center">
        <van-image
          round
          width="100px"
          height="100px"
          :src="defaultImg"
          @click="modifyImage"
        />

        <div
          class="userInfo"
          @click="
            () => {
              this.$router.push('/auth');
            }
          "
        >
          <div class="personal">
            <div class="name">{{ userName }}</div>
            <div v-if="userName" class="nameStatus">已实名</div>
            <div v-if="!userName" class="nameStatus">未实名</div>
          </div>
          <div class="phone">{{ phoneNumber }}</div>
        </div>
      </div>
    </div>

    <div class="center">
      <h3>我的应用</h3>
      <van-grid :border="false">
        <van-grid-item
          v-if="role == 1 || adminFlag == 1"
          icon="friends"
          text="白名单"
          to="/whiteList"
        />
        <van-grid-item
          v-if="role == 1 && adminFlag == 0"
          icon="balance-list"
          text="补借条"
          to="/iou"
        />
        <van-grid-item v-if="adminFlag == 0" icon="contact" text="信息认证" to="/auth" />
        <van-grid-item icon="setting" text="设置" to="/setting" />
      </van-grid>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Image as VanImage } from "vant";
import { Grid, GridItem } from "vant";
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(VanImage);
export default {
  name: "Person",
  data() {
    return {
      adminFlag :localStorage.getItem("adminFlag"),
      defaultImg: localStorage.getItem("profilePicture") ? localStorage.getItem("profilePicture") : this.$store.state.login.domainName + "/picture/1.jpg",
      show: false,
      userName: localStorage.getItem("userName"),
      phoneNumber: localStorage.getItem("phoneNumber"),
      SelectedValues: "",
      lastIndex: 0,
      role:localStorage.getItem("role"),

      images: [
        {
          value: 1,
          url: this.$store.state.login.domainName + "/picture/1.jpg",
          isMasked: false,
        },
        {
          value: 2,
          url: this.$store.state.login.domainName + "/picture/2.jpg",
          isMasked: false,
        },
        {
          value: 3,
          url: this.$store.state.login.domainName + "/picture/3.jpg",
          isMasked: false,
        },
        {
          value: 4,
          url: this.$store.state.login.domainName + "/picture/4.jpg",
          isMasked: false,
        },
        {
          value: 5,
          url:this.$store.state.login.domainName + "/picture/5.jpg",
          isMasked: false,
        },
        {
          value: 6,
          url: this.$store.state.login.domainName + "/picture/6.jpg",
          isMasked: false,
        }
      ],
    };
  },
  methods: {

    cancel(){
      this.images[this.lastIndex].isMasked = false;

    },
    // 点击头像时
    Selectedimage(data) {
      const index = data.value-1;
      this.images[this.lastIndex].isMasked = false;
      this.lastIndex = index;
      this.SelectedValues = data.url;
      this.images[index].isMasked = true; // 点击时显示遮罩层
    },
    modifyImage() {
      this.unified = this.$store.state.login.domainName,
      this.show = true;
    },

    async confirm() {
      try {
        await this.$store.dispatch("updateProfilePicture", {
          param: this.SelectedValues,
        });
        this.defaultImg = this.SelectedValues;
        this.$refs.autoCloseDialog.openDialog("修改成功");
      } catch (error) {
        this.$refs.autoCloseDialog.openErrorDialog(error);
      }
    },
  },
};
</script>

<style scoped lang="less">
@import url("@/assets/Less/index.less");
@import url("index.less");
.Dialog {
  width: 300px !important;
  background-color: white;
  .imageWapper {
    display: flex;
    justify-content: center;
    padding: 20px;
    align-items: center;
    .component-wrapper {
      /* 确保遮罩层能够相对于这个容器定位 */
      position: relative;

      /* 其他样式 */
    }
    .overlay {
      position: absolute;

      top: 0px;
      left: 0px;
      right: 0;
      bottom: 0;
      width: 100px;
      height: 100px;
      background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
      z-index: 1000; /* 确保遮罩层位于其他内容之上 */
    }
  }
}
</style>
