<template>
  <div class="auth-con">
    <div class="auth-form">
      <auto-close-dialog ref="autoCloseDialog" />
      <van-form>
        <van-cell-group inset>
          <van-field v-model="phoneNumber" label="手机号" :readonly="true" />

          <div class="input-swraps">
            <van-field
              label="验证码"
              v-model="VerificationCode"
              type="number"
              name="VerificationCode"
              placeholder="请输入验证码"
              :rules="[{ pattern: regex2, message: '请输入正确的验证码' }]"
            />
            <div style="margin-right: 1rem">
              <van-button
                native-type="button"
                class="codeBtn"
                @click="SendVerificationCode"
                :disabled="isCountdownActive"
              >
                <span v-if="isShow === true">获取验证码</span>
                <span v-else>{{ countdownTime }}秒</span>
              </van-button>
            </div>
          </div>

          <van-field
            v-model="password"
            type="password"
            name="password"
            label="交易密码"
            placeholder="请输入6位交易密码"
            :rules="[{ pattern: regex2, message: '请输入正确交易密码' }]"
          />

          <!-- 确认交易密码 -->
          <van-field
            v-model="confirmPassword"
            type="password"
            name="确认交易密码"
            label="确认交易密码"
            placeholder="请再次输入交易密码"
            :rules="[
              { pattern: regex2, message: '请输入正确交易密码' },
              {
                validator: confirmPasswordValid,
                required: true,
                message: '密码不一致，请重试',
              },
            ]"
          />
        </van-cell-group>
        <div style="margin: 16px">
          <van-button class="btn" round block @click="updatePassword">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChangePwd",
  data() {
    return {
      VerificationCode: "",
      phoneNumber: localStorage.getItem("phoneNumber"),
      username: "",
      password: "",
      confirmPassword: "",
      rules: [],
      isShow: true,
      regex2: /^\d{6}$/,
      isCountdownActive: false, // 是否正在进行倒计时
      countdownTime:""
    };
  },
  created() {
    this.checkUserName();
  },
  methods: {
    checkUserName() {
      if (!localStorage.getItem("userName")) {
        this.$router.push("/auth");
      }
    },
    confirmPasswordValid() {
      return this.password == this.confirmPassword;
    },
    // 点击发送验证码
    async SendVerificationCode(event) {
      event.stopPropagation();
      //this.$encrypt
      //new Date().getTime()
      const paramObj = {
        phoneNumber: this.phoneNumber,
        timeStamp: new Date().getTime(),
        unionId: "unionId",
      };
      let encrypted = this.$encrypt.encrypt(JSON.stringify(paramObj));
      const param = {
        param: encrypted,
      };
      try {
        let result = await this.$store.dispatch("sendVerificationCode", param);
        this.countdownTime = result.data;
        if (this.isCountdownActive) return; // 如果倒计时正在进行中，则不执行任何操作
        this.isCountdownActive = true;
        this.isShow = false;
        this.startCountdown();
        this.$refs.autoCloseDialog.openDialog("发送成功");
      } catch (error) {
        this.$refs.autoCloseDialog.openDialog(error.message);
      }
    },

    // 开启定时器
    startCountdown() {
      this.timer = setInterval(() => {
        if (this.countdownTime > 0) {
          this.countdownTime -= 1;
        } else {
          clearInterval(this.timer);
          this.isShow = true;
          //this.countdownTime = 10;
          this.isCountdownActive = false;
        }
      }, 1000);
    },

    // 关闭定时器
    beforeDestroy() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    async updatePassword() {
      const paramObj = {
        timeStamp: new Date().getTime(),
        password: this.password,
        verificationCode: this.VerificationCode,
      };
      let encrypted = this.$encrypt.encrypt(JSON.stringify(paramObj));
      const param = {
        param: encrypted,
      };
      try {
        await this.$store.dispatch("updatePassword", param);
        this.$refs.autoCloseDialog.openDialog("修改成功");
        await this.$refs.autoCloseDialog.sleep(2000);
        this.$router.push("/tabbar/person");
      } catch (error) {
        this.$refs.autoCloseDialog.openErrorDialog(error);
      }
    },
    //手机号验证规则
  },
};
</script>
<style scoped lang="less">
@import url("@/assets/Less/index.less");
@import url("index.less");
.input-swraps {
  display: flex;
  align-items: center;
  .codeBtn {
    width: 80px;
    height: 30px;
    border-radius: 15px;
    font-size: 10px;
    white-space: nowrap;
  }
}
</style>
