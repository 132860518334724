<template>
  <div class="not-found">
    <div class="content">
      <h1>404</h1>
      <p>页面未找到</p>
      <a href="/" class="home-link">返回首页</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error'
}
</script>

<style scoped>
.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
  font-family: 'Arial', sans-serif;
}

.content {
  text-align: center;
}

.content h1 {
  font-size: 100px;
  margin: 0;
  color: #333;
}

.content p {
  font-size: 24px;
  color: #666;
  margin: 20px 0 40px;
}

.home-link {
  display: inline-block;
  padding: 10px 20px;
  font-size: 18px;
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.home-link:hover {
  background-color: #0056b3;
}
</style>