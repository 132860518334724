<template>
  <div class="warrper">
    <auto-close-dialog ref="autoCloseDialog" />

    <div class="header">
      <div class="left">
        <van-icon name="friends" />
        <span>白名单列表</span>
      </div>
      <div class="add" @click="addWhiteShow">+ 新增手机号</div>
    </div>

    <van-dialog
      v-model="isShow"
      title="请输入手机号"
      show-cancel-button
      @confirm="OnSubmit"
      @cancel="Oncancel"
      class="addWarpper"
    >
      <van-form class="addWhite">
        <van-field
          v-model="newPhoneNumber"
          placeholder="请输入手机号"
          type="number"
          :rules="[{ pattern: regex1, message: '请输入正确手机号' }]"
          class="inp"
        />
      </van-form>
    </van-dialog>

    <div class="content">
      <div class="search-swrap">
        <van-search
          v-model="phoneNumber"
          @blur="handleBlur"
          shape="round"
          placeholder="请输入手机号进行搜索"
        >
        </van-search>
      </div>

      <div clss="list-swrap">
        <div class="list-header">
          <div class="title">手机号</div>
          <div class="title">名字</div>
        </div>
        <div class="list">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <van-cell v-for="item in list" :key="item.billCode">
              <van-swipe-cell>
                <div class="list-item">
                  <div class="title">{{ item.phoneNumber }}</div>
                  <div class="title">{{ item.userName ? item.userName : '未认证' }}</div>
                </div>
                <template #right>
                  <van-button
                    square
                    type="danger"
                    text="删除"
                    @click="deleteWhiteList(item.phoneNumber)"
                  />
                </template>
              </van-swipe-cell>
            </van-cell>
          </van-list>

          <!-- <van-cell v-for="item in list" :key="item.billCode">
            <van-swipe-cell>
              <div class="list-item">
                <span>{{ item.phoneNumber }}</span>
              </div>
              <template #right>
                <van-button
                  square
                  type="danger"
                  text="删除"
                  @click="deleteWhiteList(item.phoneNumber)"
                />
              </template>
            </van-swipe-cell>
          </van-cell> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "whiteList",
  data() {
    return {
      isShow: false,
      phoneNumber: "",
      newPhoneNumber: "",
      page: 1,
      list: [],
      flag: true,
      regex1: /^[1][3-9]\d{9}$/,
      loading: false,
      finished: false,
    };
  },
  methods: {
    async deleteWhiteList(phoneNumber) {
      try {
        await this.$store.dispatch("deleteWhiteList", {
          phoneNumber,
        });
        this.init();
      } catch (error) {
        this.$refs.autoCloseDialog.openErrorDialog(error);
      }
    },

    addWhiteShow() {
      this.isShow = true;
    },

    async OnSubmit() {
      if (this.newPhoneNumber) {
        let phoneNumber = this.newPhoneNumber;
        let isValidphoneNumber = this.regex1.test(phoneNumber);

        if (!isValidphoneNumber) {
          this.isShow = true;
          this.$refs.autoCloseDialog.openErrorDialog({
            message: "请输入正确的手机号",
          });
          this.newPhoneNumber = "";
          return;
        }
        try {
          await this.$store.dispatch("addWhiteList", {
            phoneNumber,
          });
          this.isShow = false;
          this.init();
          this.newPhoneNumber = "";
        } catch (error) {
          this.isShow = false;
          this.$refs.autoCloseDialog.openErrorDialog(error);
        }
      } else {
        this.$refs.autoCloseDialog.openErrorDialog({ message: "请输入手机号" });
      }
    },

    Oncancel() {
      this.newPhoneNumber = "";
    },
    async onLoad() {
      const param = {
        page: this.page,
        phoneNumber: this.phoneNumber,
      };
      try {
        const result = await this.$store.dispatch("getWhiteList", param);
        const list = result.data;
        if (list.length == 0) {
          this.finished = true;
          return;
        } else {
          this.page++;
          this.loading = false;
          this.list = [...this.list, ...list];
        }
      } catch (error) {
        this.finished = true;
        this.$refs.autoCloseDialog.openErrorDialog(error);
      }
    },
    init() {
      this.list = [];
      this.page = 1;
      this.finished = false;
      this.onLoad();
      // const param = {
      //   page: this.page,
      //   phoneNumber: this.phoneNumber,
      // };
      // let flag = true;
      // while (flag) {
      //   try {
      //     const result = await this.$store.dispatch("getWhiteList", param);
      //     if (flag) {
      //       this.flag = false;
      //     }
      //     const list = result.data;
      //     if (list.length == 0) {
      //       flag = false;
      //       return;
      //     } else {
      //       this.page++;
      //       param.page = this.page;
      //     }
      //     this.list = [...this.list, ...list];
      //   } catch (error) {
      //     flag = false;
      //     this.$refs.autoCloseDialog.openErrorDialog(error);
      //   }
      // }
    },

    handleBlur() {
      this.list = [];
      this.init();
    },
  },
};
</script>

<style lang="less" scoped>
@import url("@/assets/Less/index.less");

.warrper {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: @background;
    .left {
      margin: 15px 20px;
      span {
        padding-left: 10px;
      }
    }
    .add {
      padding-right: 20px;
      color: white;
    }
  }

  .addWarpper {
    background-color: white;
    color: black;
    width: 320px !important;

    .addWhite {
      margin: 20px 20px;
      .inp {
        margin: 10px 10px;
      }
    }
  }

  .content {
    .search-swrap {
      margin: 15px 0;
    }
    .list-header {
      height: 40px;
      display: flex;
      padding: 0 16px;
      box-sizing: border-box;
    }
    .list-header .title {
      font-size: 13px;
      font-weight: 500;
      color: #7b4c01;
      flex: 1;
      flex-shrink: 0;
      text-align: center;
    }

    .list-item {
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .list-item{
      height: 40px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      div{
        font-size: 13px;
        font-weight: 500;
        color: #333333;
        flex: 1;
        flex-shrink: 0;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
      }
    }
  }
}
</style>
