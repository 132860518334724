<template>
  <div class="auth-con">
    <auto-close-dialog ref="autoCloseDialog"/>
    <div class="label" v-if="!loginName">请先进行认证信息</div>
    <div class="label" v-if="loginName">您已认证信息</div>
    <div class="auth-form" v-if="!loginName || role == 1">
      <van-form>
        <van-cell-group inset>
          <!-- 姓名 -->
          <van-field
            v-model="userName"
            name="姓名"
            label="姓名"
            placeholder="请输入姓名"
            :rules="[{ required: true, message: '请填写姓名' }]"
          />
          <!-- 身份证号码 -->
          <van-field
            v-model="idCard"
            name="personIdCard"
            label="身份证"
            placeholder="请输入身份证号码"
            :rules="[{ pattern: regexIdCard, message: '请输入正确身份证号码' }]"
          />
          <van-field
            v-model="phoneNumber"
            name="phoneNumber"
            label="手机号"
            :readonly=true           />
          <!-- ...rules.idCode, -->

          <!-- 交易密码 -->
          
          <van-field
            v-model="password"
            type="password"
            name="password"
            label="交易密码"
            placeholder="请输入6位交易密码"
            :rules="[{ pattern: regexPassword, message: '请输入正确交易密码' }]"
          />

          <!-- 确认交易密码 -->
          <van-field
            v-model="confirmPassword"
            type="password"
            name="确认交易密码"
            label="确认交易密码"
            placeholder="请再次输入交易密码"
            :rules="[
              { pattern: regexPassword, message: '请输入正确交易密码' },
              {
                validator: confirmPasswordValid,
                required: true,
                message: '密码不一致，请重试',
              },
            ]"
          />
        </van-cell-group>
        <div style="margin: 16px">
          <van-button class="btn" round block @click="authentication">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
    <div class="auth-form" v-if="loginName && role == 0">
      <van-form>
        <van-cell-group inset>
          <!-- 姓名 -->
          <van-field
            v-model="userName"
            name="姓名"
            label="姓名"
            placeholder="请输入姓名"
            :readonly=true
            :rules="[{ required: true, message: '请填写姓名' }]"
          />
          <!-- 身份证号码 -->
          <van-field
            v-model="idCard"
            name="personIdCard"
            label="身份证"
            placeholder="请输入身份证号码"
            :readonly=true
            :rules="[{ pattern: regexIdCard, message: '请输入正确身份证号码' }]"
          />
          <van-field
            v-model="phoneNumber"
            name="phoneNumber"
            label="手机号"
            :readonly=true          
            />
        </van-cell-group>
      </van-form>
    </div>
  </div>
</template>

<script>

export default {
  name: "Auth",
  data() {
    return {
      loginName: localStorage.getItem("userName"),
      password: "",
      confirmPassword: "",
      idCard: localStorage.getItem("idCard"),
      role: localStorage.getItem("role"),
      userName: localStorage.getItem("userName"),
      phoneNumber: localStorage.getItem("phoneNumber"),
      regexIdCard:
        /^[1-9]\d{5}(18|19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}[\dXx]$/,
      regexPassword: /^\d{6}$/,
      rules: [{ idCode: [] }],
    };
  },
  methods: {
    async authentication() {
      const paramObj = {
        userName: this.userName,
        idCard: this.idCard,
        timeStamp: new Date().getTime(),
        password: this.password,
      };
      let encrypted = this.$encrypt.encrypt(JSON.stringify(paramObj));
      const param = {
        param: encrypted,
      };
      try {
        await this.$store.dispatch("authentication", param);
        //设置身份证，userName
        localStorage.setItem("userName", this.userName);
        localStorage.setItem("idCard", this.idCard);
        this.$refs.autoCloseDialog.openDialog("认证成功");
        await this.$refs.autoCloseDialog.sleep(2000);
        this.$router.push("/tabbar/person");
      } catch (error) {
        this.$refs.autoCloseDialog.openErrorDialog(error);
      }
    },
    confirmPasswordValid() {
      return this.password == this.confirmPassword;
    },
  },
};
</script>
<style scoped lang="less">
@import url("@/assets/Less/index.less");
@import url("index.less");
</style>
