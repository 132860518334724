<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- <LoginPage/> -->
    <router-view />
  </div>
</template>

<script>
//import { RouterView } from 'vue-router';
//import LoginPage from './components/LoginPage/index.vue';

export default {
  name: "App",
  components: {
    //LoginPage
  },
};
</script>

<style>
/* .notify{
  width: 100px;
  height: 50px;
  margin-top: 300px; 
  border-radius: 25% ;
} */

.notify {
  width: 60% !important;

  margin-top: 300px;
  border-radius: 20px;
  white-space: normal;

  left: 0;  
  right: 0;  
  top:350px !important;
  margin: 0 auto;  
}
</style>
