var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-con"},[_c('auto-close-dialog',{ref:"autoCloseDialog"}),_c('PasswordPop',{ref:"passwordPop",on:{"onPassword":_vm.obtainPassword}}),_c('div',{staticClass:"header"},[_c('div',{staticClass:"header-center"},[(_vm.role == 0 && _vm.adminFlag != 1)?_c('div',{staticClass:"label"},[_vm._v("待还金额（元）")]):_vm._e(),(_vm.role == 1 || _vm.adminFlag == 1)?_c('div',{staticClass:"label"},[_vm._v("待收金额（元）")]):_vm._e(),_c('div',{staticClass:"label-amount"},[_vm._v(_vm._s(_vm.amount))])])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"search-swrap"},[_c('van-search',{attrs:{"shape":"round","placeholder":"请输入手机号进行搜索"},on:{"search":_vm.handleBlur},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})],1),_c('van-tabs',{attrs:{"sticky":""},on:{"click":_vm.onClick},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tab',{attrs:{"title":"全部","name":"0"}},[_c('div',{staticClass:"list-swrap"},[_c('div',{staticClass:"list-header"},[_c('div',{staticClass:"title"},[_vm._v("出资人")]),(_vm.role == 0 && _vm.adminFlag != 1)?_c('div',{staticClass:"title"},[_vm._v("借款金额")]):_vm._e(),(_vm.role == 1 || _vm.adminFlag == 1)?_c('div',{staticClass:"title"},[_vm._v("待还金额")]):_vm._e(),_c('div',{staticClass:"title"},[_vm._v("到期时间")]),_c('div',{staticClass:"title"},[_vm._v("状态")])]),_c('div',{staticClass:"list"},[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.list),function(item){return _c('van-cell',{key:item.index},[_c('van-swipe-cell',{scopedSlots:_vm._u([(
                      _vm.adminFlag != 1 &&
                      _vm.role == 1 &&
                      [0, 20, 30].includes(item.status)
                    )?{key:"right",fn:function(){return [_c('van-button',{attrs:{"square":"","type":"danger","text":"删除"},on:{"click":function($event){return _vm.deleteItem(item.billCode)}}})]},proxy:true}:null],null,true)},[_c('div',{staticClass:"list-item",on:{"click":function($event){return _vm.goToDtail(item.billCode)}}},[_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(item.userName))])]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.amount)+"元")]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.expirationDate))]),(_vm.mature == 0)?_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.getStatusHtml(item.status, item.expirationDate))}}):_vm._e()])])],1)}),1)],1)])]),_c('van-tab',{attrs:{"title":"已完成","name":"2"}},[_c('div',{staticClass:"list-swrap"},[_c('div',{staticClass:"list-header"},[_c('div',{staticClass:"title"},[_vm._v("出资人")]),(_vm.role == 0 && _vm.adminFlag != 1)?_c('div',{staticClass:"title"},[_vm._v("借款金额")]):_vm._e(),(_vm.role == 1 || _vm.adminFlag == 1)?_c('div',{staticClass:"title"},[_vm._v("待还金额")]):_vm._e(),_c('div',{staticClass:"title"},[_vm._v("到期时间")]),_c('div',{staticClass:"title"},[_vm._v("状态")])]),_c('div',{staticClass:"list"},[_c('van-list',{ref:"checkbox",attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.list),function(item){return _c('van-cell',{key:item.billCode},[_c('van-swipe-cell',{scopedSlots:_vm._u([(
                      _vm.adminFlag != 1 &&
                      _vm.role == 1 &&
                      [0, 20, 30].includes(item.status)
                    )?{key:"right",fn:function(){return [_c('van-button',{attrs:{"square":"","type":"danger","text":"删除"},on:{"click":function($event){return _vm.deleteItem(item.billCode)}}})]},proxy:true}:null],null,true)},[_c('div',{staticClass:"list-item",on:{"click":function($event){return _vm.goToDtail(item.billCode)}}},[_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(item.userName))])]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.amount)+"万")]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.expirationDate))]),(_vm.mature == 2)?_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.getStatusHtml(item.status, item.expirationDate))}}):_vm._e()])])],1)}),1)],1)])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }