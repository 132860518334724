<template>
  <div id="login">
    <auto-close-dialog ref="autoCloseDialog" />
    <img src="@/assets/images/logo1.jpg" alt="" class="logo" />

    <!--src="@/assets/images/logo.png " -->
    <div class="label">hi，欢迎来到橙子合约!</div>
    <div class="form-swrap">
      <van-form>
        <div class="input-swrap">
          <van-icon name="phone" size="2rem" color="#666666" />
          <van-field
            v-model="phoneNumber"
            name="phoneNumber"
            type="number"
            placeholder="请输入手机号"
            :rules="[{ pattern: regex1, message: '请输入正确手机号' }]"
            style="background-color: #f9f9f9"
          />
        </div>

        <div class="input-swraps">
          <van-icon name="lock" size="2rem" color="#666666" />
          <van-field
            v-model="VerificationCode"
            type="number"
            name="VerificationCode"
            ref="myField"
            placeholder="请输入验证码"
            :rules="[{ pattern: regex2, message: '请输入正确的验证码' }]"
            style="background-color: #f9f9f9"
            oninput="if(value.length>6) value = value.slice(0,6)"
          />
          <div style="margin-right: 1rem">
            <van-button
              native-type='button'
              class="codeBtn"
              @click="SendVerificationCode"
              :disabled="isCountdownActive"
            >
              <span v-if="isShow === true">获取验证码</span>
              <span v-else>{{ countdownTime }}秒</span>
            </van-button>
          </div>
        </div>
        <div class="title">
          <van-checkbox v-model="checked" icon-size="18px"></van-checkbox>

          <span style="margin-left: 15px"> 登录既同意 </span>
          <span>
            <a  :href="UserAgreement"> 《用户协议》 </a>
            <i>,</i>
            <a :href="PrivacyPolicy"> 《隐私政策》 </a>
          </span>
        </div>
        <div style="margin: 16px">
          <!-- <router-link to="/tabbar/home"> -->
          <van-button round block type="info" @click="OnSubmit" class="btn">
            提交
          </van-button>
          <!-- </router-link> -->
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
export default {
  name: "LoginPage",
  data() {
    return {
      UserAgreement: this.$store.state.login.domainName + "/%E7%94%A8%E6%88%B7%E5%8D%8F%E8%AE%AE.html",
      PrivacyPolicy: this.$store.state.login.domainName + "/%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.html",
      phoneNumber: "",
      VerificationCode: "", //用户填写的验证码
      reqVerificationCode: 123456, //接口返回的验证码
      checked: false,
      isShow: true,

      countdownTime: 10, // 初始倒计时时间（秒）
      isCountdownActive: false, // 是否正在进行倒计时
      timer: null, // 定时器引用

      regex1: /^[1][3-9]\d{9}$/,
      regex2: /^\d{6}$/,
    };
  },
  methods: {
    // 点击发送验证码
    async SendVerificationCode() {
      //this.$encrypt
      //new Date().getTime()
      const paramObj = {
        phoneNumber: this.phoneNumber,
        timeStamp: new Date().getTime(),
        unionId: "unionId",
      };
      let encrypted = this.$encrypt.encrypt(JSON.stringify(paramObj));
      const param = {
        param: encrypted,
      };
      try {
        let result = await this.$store.dispatch("sendVerificationCode", param);
        this.countdownTime = result.data;
        if (this.isCountdownActive) return; // 如果倒计时正在进行中，则不执行任何操作
        this.isCountdownActive = true;
        this.isShow = false;
        this.startCountdown();
        this.$refs.autoCloseDialog.openDialog("发送成功");
      } catch (error) {
        this.$refs.autoCloseDialog.openDialog(error.message);
      }
    },

    // 开启定时器
    startCountdown() {
      this.timer = setInterval(() => {
        if (this.countdownTime > 0) {
          this.countdownTime -= 1;
        } else {
          clearInterval(this.timer);
          this.isShow = true;
          //this.countdownTime = 10;
          this.isCountdownActive = false;
        }
      }, 1000);
    },

    // 关闭定时器
    beforeDestroy() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },

    //登录提交
    async OnSubmit() {
      if (this.checked) {
        if (this.phoneNumber && this.VerificationCode) {
          // 整理参数
          // const { phoneNumber, VerificationCode } = this;
          const info = {
            phoneNumber: this.phoneNumber,
            verificationCode: this.VerificationCode,
          };
          const loginInfo = JSON.stringify(info);
          // 发送登录请求
          try {
            const result = await this.$store.dispatch("login", loginInfo);
            let url = '/tabbar/home';
            if (result.adminFlag == 1){
              url = '/tabbar/adminHome';
            }
            const redirect = this.$route.query.redirect || url;
            this.$router.push(redirect);
          } catch (error) {
            this.$refs.autoCloseDialog.openDialog(error.message);
          }
        }
      } else {
        this.$refs.autoCloseDialog.openDialog(
          "请先同意登录既同意《用户协议》，《隐私政策》"
        );
      }
    },
  },
};
</script>
<style scoped lang="less">
@import url("@/assets/Less/index.less");

.logo {
  width: 120px;
  height: 120px;
  margin-top: 24px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.5);
}
.label {
  font-size: 28px;
  font-weight: 800;
  color: #333333;
  margin-top: 10px;
}
#login {
  width: 100%;
  height: 100vh;
  background-image: url(@image);
  background-repeat: no-repeat;
  background-size: 100vw 212px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}
.form-swrap {
  margin-top: 60px;
  width: 335px;
}
.input-swrap {
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  background: #f9f9f9;
  margin-bottom: 20px;
}

.input-swraps {
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  background: #f9f9f9;
  margin-bottom: 20px;
  .codeBtn {
    width: 80px;
    height: 30px;
    border-radius: 15px;
    font-size: 10px;
    white-space: nowrap;
  }
}

.title {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  span {
    a {
      color: @color;
    }
  }
}
.btn {
  background: @background;
  border: none;
}
</style>
