<template>
  <div class="home-con">
    <auto-close-dialog ref="autoCloseDialog"/>
    <PasswordPop @onPassword="obtainPassword" ref="passwordPop"></PasswordPop>
    <div class="header">
      <div class="header-center">
        <div class="label" v-if="role == 0 && adminFlag != 1">待还金额（元）</div>
        <div class="label" v-if="role == 1 || adminFlag == 1">待收金额（元）</div>
        <div class="label-amount">{{ amount }}</div>
      </div>
    </div>

    <!-- tabs -->
    <div class="content">
      <div class="search-swrap">
        <van-search
            v-model="phoneNumber"
            @search="handleBlur"
            shape="round"
            placeholder="请输入手机号进行搜索"
        >
        </van-search>
      </div>
      <van-tabs v-model="active" @click="onClick" sticky>
        <van-tab title="全部" name="0">
          <div class="list-swrap">
            <div class="list-header">
              <div class="title">出资人</div>
              <div class="title" v-if="role == 0 && adminFlag != 1">借款金额</div>
              <div class="title" v-if="role == 1 || adminFlag == 1">待还金额</div>
              <div class="title">到期时间</div>
              <div class="title">状态</div>
            </div>

            <div class="list">
              <van-list
                  v-model="loading"
                  :finished="finished"
                  finished-text="没有更多了"
                  @load="onLoad"
              >
                <van-cell v-for="item in list" :key="item.index">
                  <van-swipe-cell>
                    <div class="list-item" @click="goToDtail(item.billCode)">
                      <div class="title">
                        <span>{{ item.userName }}</span>
                      </div>
                      <div class="title">{{ item.amount }}元</div>
                      <div class="title">{{ item.expirationDate }}</div>
                      <div
                          v-if="mature == 0"
                          class="title"
                          v-html="getStatusHtml(item.status, item.expirationDate)"
                      >
                      </div>
                    </div>

                    <template
                        #right
                        v-if="
                        adminFlag != 1 &&
                        role == 1 &&
                        [0, 20, 30].includes(item.status)
                      "
                    >
                      <van-button
                          square
                          type="danger"
                          text="删除"
                          @click="deleteItem(item.billCode)"
                      />
                    </template>
                  </van-swipe-cell>
                </van-cell>
              </van-list>
            </div>
          </div>
        </van-tab>
        <van-tab title="已完成" name="2">
          <div class="list-swrap">
            <div class="list-header">
              <div class="title">出资人</div>
              <div class="title" v-if="role == 0 && adminFlag != 1">借款金额</div>
              <div class="title" v-if="role == 1 || adminFlag == 1">待还金额</div>
              <div class="title">到期时间</div>
              <div class="title">状态</div>
            </div>
            <div class="list">
              <van-list
                  v-model="loading"
                  :finished="finished"
                  finished-text="没有更多了"
                  ref="checkbox"
                  @load="onLoad"
              >
                <van-cell v-for="item in list" :key="item.billCode">
                  <van-swipe-cell>
                    <div class="list-item" @click="goToDtail(item.billCode)">
                      <div class="title">
                        <span>{{ item.userName }}</span>
                      </div>
                      <div class="title">{{ item.amount }}万</div>
                      <div class="title">{{ item.expirationDate }}</div>
                      <div
                          v-if="mature == 2"
                          class="title"
                          v-html="getStatusHtml(item.status, item.expirationDate)"
                      >
                      </div>
                    </div>
                    <template
                        #right
                        v-if="
                        adminFlag != 1 &&
                        role == 1 &&
                        [0, 20, 30].includes(item.status)
                      "
                    >
                      <van-button
                          square
                          type="danger"
                          text="删除"
                          @click="deleteItem(item.billCode)"
                      />
                    </template>
                  </van-swipe-cell>
                </van-cell>
              </van-list>
            </div>
          </div
          >
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      active: 0,
      amount: 0,
      list: [],
      phoneNumber: "",
      page: 1,
      mature: 0,
      flag: true,
      role: localStorage.getItem("role"),
      adminFlag: localStorage.getItem("adminFlag"),
      password: "",
      billCode: "",
      loading: false,
      finished: false,
      rolePhoneNumber : this.$route.query.phoneNumber,
    };
  },
  created() {
    //this.init();
  },
  methods: {
    getStatusHtml(status, expirationDate) {
      if (status === 0) {
        return `<span style="color: #87ceeb;">待确认</span>`;
      } else if (status === 1) {
        return `<span style="color: #87ceeb;">销账待确认</span>`;
      } else if (status === 2) {
        return `<span style="color: #87ceeb;">延期待确认</span>`;
      } else if (status === 10) {
        const now = new Date();
        // 设置明天凌晨 0 点的时间
        const tomorrowMidnight = new Date(now);
        tomorrowMidnight.setHours(0, 0, 0, 0); // 将时间设置为凌晨 0 点
        const expirationDateTime =  new Date(expirationDate);
        expirationDateTime.setHours(0, 0, 0, 0); // 将时间设置为凌晨 0 点
        if (tomorrowMidnight.getTime() <= expirationDateTime.getTime()) {
          return `<span style="color: #3ac794;">进行中</span>`;
        }
        expirationDateTime.setDate(expirationDateTime.getDate() + 1);
        if (tomorrowMidnight.getTime() === expirationDateTime.getTime()) {
          return `<span style="color: red;">宽限期</span>`;
        }
        return `<span style="color: red;">已逾期</span>`;
      } else if (status === 20) {
        return `<span style="color: #3ac794;">已完成</span>`;
      } else if (status === 30) {
        return `<span style="color: red;">被拒绝</span>`;
      }
      return `<span style="color: red;">未知状态${status}</span>`;
    },
    deleteItem(billCode) {
      this.billCode = billCode;
      // 显示输入密码框
      this.$refs.passwordPop.isSheetShow(true);
    },

    obtainPassword(password) {
      this.password = password;
      this.deleteBill();
    },

    async deleteBill() {
      const paramObj = {
        timeStamp: new Date().getTime(),
        password: this.password,
        billCode: this.billCode,
      };

      let encrypted = this.$encrypt.encrypt(JSON.stringify(paramObj));

      const param = {
        param: encrypted,
      };

      try {
        await this.$store.dispatch("deleteBill", param);
        this.$refs.autoCloseDialog.openDialog("提交成功");
        this.$refs.passwordPop.isSheetShow(false);
        this.init();
      } catch (error) {
        this.password = "";
        this.$refs.autoCloseDialog.openErrorDialog(error);
      }
    },

    onClick(name) {
      this.mature = name;
      this.init();
    },
    handleBlur() {
      this.init();
    },

    goToDtail(billCode) {
      this.$router.push({
        name: "loanContent",
        query: {
          billCode: billCode,
        },
      });
      // if (status == 0) {
      //   this.$router.push({
      //     name: "loanContent",
      //     query: {
      //       billCode: billCode,
      //     },
      //   });
      // } else {
      //   this.$router.push({
      //     name: "detail",
      //     query: {
      //       billCode: billCode,
      //     },
      //   });
      // }
    },
    async onLoad() {
      const param = {
        page: this.page++,
        mature: this.mature,
        phoneNumber: this.phoneNumber,
        rolePhoneNumber: this.rolePhoneNumber
      };
      try {
        const result = await this.$store.dispatch("getBillList", param);
        if (result.data.amount != null) {
          this.amount = result.data.amount;
        }
        const list = result.data.list;
        if (list.length == 0) {
          this.finished = true;
          return;
        } else {
          this.list = [...this.list, ...list];
          this.loading = false;
        }
      } catch (error) {
        this.finished = true;
        this.$refs.autoCloseDialog.openErrorDialog(error);
      }
    },
    init() {
      this.list = [];
      this.page = 1;
      this.amount = 0;
      this.finished = false;
      this.onLoad();
    },
    // async init() {
    //   this.list = [];
    //   this.page = 1;
    //   const param = {
    //     page: this.page,
    //     mature: this.mature,
    //     size: 20,
    //     phoneNumber: this.phoneNumber,
    //   };
    //   let flag = true;
    //   while (flag) {
    //     try {
    //       const result = await this.$store.dispatch("getBillList", param);
    //       if (flag && result.data.amount != null) {
    //         this.amount = result.data.amount;
    //         this.flag = false;
    //       }
    //       const list = result.data.list;
    //       if (list.length == 0) {
    //         flag = false;
    //         return;
    //       } else {
    //         this.page++;
    //         param.page = this.page;
    //       }
    //       this.list = [...this.list, ...list];
    //     } catch (error) {
    //       flag = false;
    //       this.$refs.autoCloseDialog.openErrorDialog(error);
    //     }
    //   }
    // },
  },
};
</script>

<style scoped lang="less">
/* 你的样式 */
@import url("@/assets/Less/index.less");
@import url("index.less");
</style>
