<template>
  <div>
    <auto-close-dialog ref="autoCloseDialog" />
    <PasswordPop @onPassword="obtainPassword" ref="passwordPop"></PasswordPop>

    <van-popup v-model="isShow" position="bottom" :style="{ height: '40%' }">
      <van-datetime-picker
        v-model="expirationDate"
        type="date"
        title="选择延期时间"
        :min-date="minDate"
        :formatter="formatter"
        @confirm="confirmExpirationDate"
        @cancel="cancelDate"
      />
    </van-popup>
    <!-- <van-nav-bar
        title="账单详情"
        left-text="返回"
        right-text="申请延期账单"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onExtensionBill"
      /> -->
    <div class="header">

      <div class="left"><van-icon name="bill" /><span>账单详情</span></div>
      <div class="reight" v-if="role == 1 && status == 1">
        <div>
          <span @click="onCompletedBill"><van-icon name="passed" /><span>销账</span></span>
          <span @click="onExtensionBill"><van-icon name="todo-list-o" /><span>延期账单</span></span>
        </div>
      </div>
    </div>
    <div class="details-con">
      <div class="note-header">
        <div class="note-header_content">
          <div class="note-header_content-left">
            <div>
              <div class="title">周转</div>
            </div>
            <div class="note-info-swrap">
              <div class="label">借款人</div>
              <div class="sub-label" style="min-width: 47px">
                {{ role == 0 ? loginName : userName }}
              </div>
            </div>
            <div class="note-info-swrap">
              <div class="label">出借人</div>
              <div class="sub-label" style="min-width: 47px">
                {{ role == 0 ? userName : loginName }}
              </div>
            </div>
          </div>
        </div>
        <div class="note-header_footer">
          <div class="footer-info">
            <div class="label">
              借款金额
              <div class="sub-label">(元)</div>
            </div>
            <div class="value">{{ amount }}</div>
          </div>
          <div class="footer-info">
            <div class="label">
              利率
              <div class="sub-label">（%）</div>
            </div>
            <div class="value">{{ annualizedInterestRate }}</div>
          </div>
          <div class="footer-info">
            <div class="label">状态</div>
            <div class="value" v-if="status === 0">待确认</div>
            <div class="value" v-if="status === 1">进行中</div>
            <div class="value" style="color: #3ac794" v-if="status === 2">
              已完成
            </div>
            <div class="value" style="color: red" v-if="status === 3">
              被拒绝
            </div>
            <div class="value" v-if="status === 4">已取消</div>
          </div>
        </div>
      </div>
      <div class="delay-header">
        <div class="lines"></div>
        <div class="delay-title">延期日志</div>
      </div>
      <div class="delay-title-info van-row">
        <div class="van-col van-col--6">
          <span class="delay-title">发起人</span>
        </div>
        <div class="van-col van-col--6">
          <span class="delay-title">延期至</span>
        </div>
        <div class="van-col van-col--6">
          <span class="delay-title">上次到期</span>
        </div>
        <div class="van-col van-col--6">
          <span class="delay-title">创建时间</span>
        </div>
      </div>

      <div
        class="delay-cell-info van-row"
        v-for="item in billHistoryVoList"
        :key="item.index"
      >
        <div class="van-col van-col--6">
          <span class="delay-title">{{
            role == 0 ? userName : loginName
          }}</span>
        </div>
        <div class="van-col van-col--6">
          <span class="delay-title">{{ item.newTime }}</span>
        </div>
        <div class="van-col van-col--6">
          <span class="delay-title">{{ item.oldTime }}</span>
        </div>
        <div class="van-col van-col--6">
          <span class="delay-title be_paid">{{ item.createTime }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Detail",
  data() {
    return {
      userName: "",
      amount: 0,
      status: 0,
      annualizedInterestRate: 0,
      billHistoryVoList: [],
      loginName: localStorage.getItem("userName"),
      role: localStorage.getItem("role"),
      idetailsstatus: 4,
      password: "",
      expirationDate: "",
      isShow: false,
      minDate: new Date(),
      billCode:"",
      operateStatus:0,
    };
  },
  created() {
    this.init();
  },
  methods: {
    obtainPassword(password) {
      this.password = password;
      this.$refs.passwordPop.isSheetShow(false); //用户填写密码之后 隐藏输入密码弹框
      if (this.operateStatus == 1) {
        this.completedBill();
      }else if (this.operateStatus == 2){
        this.extensionBill();
      }
    
    },
    onCompletedBill(){
      this.operateStatus = 1;
      this.$refs.passwordPop.isSheetShow(true);
    },
    onExtensionBill() {
      this.isShow = true;
    },
    async completedBill() {
      const paramObj = {
        password: this.password,
        timeStamp: new Date().getTime(),
        billCode: this.billCode
      };
      let encrypted = this.$encrypt.encrypt(JSON.stringify(paramObj));
      try {
        await this.$store.dispatch("completedBill", {
          param: encrypted,
        });
        this.init();
        this.$refs.autoCloseDialog.openDialog("销账成功");
      } catch (error) {
        this.$refs.autoCloseDialog.openErrorDialog(error);
      }
    },
    async extensionBill() {
      const paramObj = {
        password: this.password,
        timeStamp: new Date().getTime(),
        billCode: this.billCode,
        expirationDate: this.expirationDate,
      };

      let encrypted = this.$encrypt.encrypt(JSON.stringify(paramObj));

      try {
        await this.$store.dispatch("extensionBill", {
          param: encrypted,
        });
        this.init();
        this.$refs.autoCloseDialog.openDialog("延期信息提交成功");
      } catch (error) {
        this.$refs.autoCloseDialog.openErrorDialog(error);
      }
    },
    // 确认延期时间
    confirmExpirationDate(value) {
      this.expirationDate = this.getDateStr(value);
      this.isShow = false;
      this.operateStatus = 2;
      this.$refs.passwordPop.isSheetShow(true); //用户填完延期时间后 显示输入密码弹框
    },
    getDateStr(value) {
      if (value instanceof Date) {
        const year = value.getFullYear();
        const month = (value.getMonth() + 1).toString().padStart(2, "0");
        const day = value.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
      }
      return "";
    },
    // 取消延期时间
    cancelDate() {
      this.isShow = false;
      this.expirationDate = new Date(); // 重置当前时间（可选）
    },
    // 格式化时间选择器中的选项文字
    formatter(type, value) {
      if (type === "year") {
        return `${value}年`;
      } else if (type === "month") {
        return `${value}月`;
      } else if (type === "day") {
        return `${value}日`;
      } else if (type === "hour") {
        return `${value}时`;
      } else if (type === "minute") {
        return `${value}分`;
      }
      return value;
    },
    async init() {
      this.billCode = this.$route.query.billCode;
      const param = {
        billCode: this.billCode,
      };
      try {
        const result = await this.$store.dispatch("getBillInfoByCode", param);
        this.userName = result.data.userName;
        this.amount = result.data.amount;
        this.annualizedInterestRate = result.data.annualizedInterestRate;
        this.billHistoryVoList = result.data.billHistoryVoList;
        this.status = result.data.status;
        let dateString = result.data.expirationDate;
        let date = new Date(dateString.split('-')[0], dateString.split('-')[1] - 1, dateString.split('-')[2]);
        date.setDate(date.getDate() + 1);
        this.minDate = date;
      } catch (error) {
        this.$refs.autoCloseDialog.openErrorDialog(error);
      }
    },
  },
};
</script>
<style scoped lang="less">
@import url("index.less");
@import url("@/assets/Less/index.less");
</style>
