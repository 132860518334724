// MyCustomError.js  
class MyCustomError extends Error {  
    constructor(message, code) {  
      super(message);  
      this.code = code;
    
      // 维护 stack 属性的完整性  
      // 这一步在调用 super() 后自动完成，因为 super() 调用 Error 构造函数并设置 stack  
      // 但为了清晰起见，这里再次提及  
    }  
    
    // 你可以在这里添加其他方法或属性  
  }  
    
  // 导出 MyCustomError 类，以便其他文件可以使用  
  export default MyCustomError;