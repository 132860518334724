import {
  getBillList,
  getBillAmountSum,
  getBillInfoByCode,
  deleteBill,
  extensionBill,
  getBillContract,
} from "@/api";
import MyCustomError from "../MyCustomError.js";
const state = {
  count: 0,
};

const mutations = {};

const actions = {
  async getBillList(c, data) {
    const result = await getBillList(data);
    if (result.code == 200) {
      return result;
    } else {
      return Promise.reject(new MyCustomError(result.message, result.code));
    }
  },
  async getBillAmountSum(c, data) {
    const result = await getBillAmountSum(data);
    if (result.code == 200) {
      return result;
    } else {
      return Promise.reject(new MyCustomError(result.message, result.code));
    }
  },
  async getBillInfoByCode(c, data) {
    const result = await getBillInfoByCode(data);
    if (result.code == 200) {
      return result;
    } else {
      return Promise.reject(new MyCustomError(result.message, result.code));
    }
  },
  async getBillContract(c, data) {
    const result = await getBillContract(data);
    if (result.code == 200) {
      return result;
    } else {
      return Promise.reject(new MyCustomError(result.message, result.code));
    }
  },

  async deleteBill(c, data) {
    const result = await deleteBill(data);
    if (result.code == 200) {
      return result;
    } else {
      return Promise.reject(new MyCustomError(result.message, result.code));
    }
  },

  async extensionBill(c, data) {
    const result = await extensionBill(data);
    if (result.code == 200) {
      return result;
    } else {
      return Promise.reject(new MyCustomError(result.message, result.code));
    }
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
