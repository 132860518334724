var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-con"},[_c('auto-close-dialog',{ref:"autoCloseDialog"}),(!_vm.loginName)?_c('div',{staticClass:"label"},[_vm._v("请先进行认证信息")]):_vm._e(),(_vm.loginName)?_c('div',{staticClass:"label"},[_vm._v("您已认证信息")]):_vm._e(),(!_vm.loginName || _vm.role == 1)?_c('div',{staticClass:"auth-form"},[_c('van-form',[_c('van-cell-group',{attrs:{"inset":""}},[_c('van-field',{attrs:{"name":"姓名","label":"姓名","placeholder":"请输入姓名","rules":[{ required: true, message: '请填写姓名' }]},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}}),_c('van-field',{attrs:{"name":"personIdCard","label":"身份证","placeholder":"请输入身份证号码","rules":[{ pattern: _vm.regexIdCard, message: '请输入正确身份证号码' }]},model:{value:(_vm.idCard),callback:function ($$v) {_vm.idCard=$$v},expression:"idCard"}}),_c('van-field',{attrs:{"name":"phoneNumber","label":"手机号","readonly":true},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('van-field',{attrs:{"type":"password","name":"password","label":"交易密码","placeholder":"请输入6位交易密码","rules":[{ pattern: _vm.regexPassword, message: '请输入正确交易密码' }]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('van-field',{attrs:{"type":"password","name":"确认交易密码","label":"确认交易密码","placeholder":"请再次输入交易密码","rules":[
            { pattern: _vm.regexPassword, message: '请输入正确交易密码' },
            {
              validator: _vm.confirmPasswordValid,
              required: true,
              message: '密码不一致，请重试',
            },
          ]},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1),_c('div',{staticStyle:{"margin":"16px"}},[_c('van-button',{staticClass:"btn",attrs:{"round":"","block":""},on:{"click":_vm.authentication}},[_vm._v(" 提交 ")])],1)],1)],1):_vm._e(),(_vm.loginName && _vm.role == 0)?_c('div',{staticClass:"auth-form"},[_c('van-form',[_c('van-cell-group',{attrs:{"inset":""}},[_c('van-field',{attrs:{"name":"姓名","label":"姓名","placeholder":"请输入姓名","readonly":true,"rules":[{ required: true, message: '请填写姓名' }]},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}}),_c('van-field',{attrs:{"name":"personIdCard","label":"身份证","placeholder":"请输入身份证号码","readonly":true,"rules":[{ pattern: _vm.regexIdCard, message: '请输入正确身份证号码' }]},model:{value:(_vm.idCard),callback:function ($$v) {_vm.idCard=$$v},expression:"idCard"}}),_c('van-field',{attrs:{"name":"phoneNumber","label":"手机号","readonly":true},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }