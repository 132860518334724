<template>
  <div class="home-con">
    <auto-close-dialog ref="autoCloseDialog"/>
    <div class="header">
      <div class="header-center">
        <div class="label">待收金额（元）</div>
        <div class="label-amount">{{ amount }}</div>
      </div>
    </div>

    <!-- tabs -->
    <div class="content">
      <div class="search-swrap">
        <van-search
            v-model="phoneNumber"
            @blur="handleBlur"
            shape="round"
            placeholder="请输入手机号进行搜索"
        >
        </van-search>
      </div>
      <div clss="list-swrap">
        <div class="list-header">
          <div class="title">手机号</div>
          <div class="title">名字</div>
        </div>
        <div class="list">
          <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
          >
            <van-cell v-for="item in list" :key="item.billCode">
              <van-swipe-cell>
                <div class="list-item" @click="goToDtail(item.phoneNumber)">
                  <div class="title">{{ item.phoneNumber }}</div>
                  <div class="title">{{ item.userName ? item.userName : '未认证' }}</div>
                </div>
              </van-swipe-cell>
            </van-cell>
          </van-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      amount: 0,
      list: [],
      phoneNumber: "",
      page: 1,
      loading: false,
      finished: false,
    };
  },
  created() {
    this.checkAdminFlag();
    this.getSumAmount();
  },
  methods: {
    checkAdminFlag() {
      if (localStorage.getItem("adminFlag") != 1) {
        this.$router.push("/tabbar/home");
      }
    },
    handleBlur() {
      this.init();
    },

    goToDtail(phoneNumber) {
      //this.$router.push('/tabbar/home');
      this.$router.push({
        path: '/tabbar/home',
        query: {
          phoneNumber: phoneNumber,
        },
      });
    },
    async onLoad() {
      const param = {
        page: this.page,
        phoneNumber: this.phoneNumber,
      };
      try {
        const result = await this.$store.dispatch("getWhiteList", param);
        const list = result.data;
        if (list.length === 0) {
          this.finished = true;
        } else {
          this.page++;
          this.loading = false;
          this.list = [...this.list, ...list];
        }
      } catch (error) {
        this.finished = true;
        this.$refs.autoCloseDialog.openErrorDialog(error);
      }
    },
    async getSumAmount () {
      const param = {};
      try {
        const result = await this.$store.dispatch("getBillAmountSum", param);
        const amount = result.data;
        if (amount) {
          this.amount = amount;
        }
      } catch (error) {
        this.$refs.autoCloseDialog.openErrorDialog(error);
      }
    },
    init() {
      this.list = [];
      this.page = 1;
      this.amount = 0;
      this.finished = false;
      this.onLoad();
      this.getSumAmount();
    },
  },
};
</script>

<style scoped lang="less">
/* 你的样式 */
@import url("@/assets/Less/index.less");
@import url("index.less");
</style>
