<template>
  <van-dialog
    v-model="showDialog"
    class="dialogClass"
    :overlay="false"
    :message="message"
    :show-confirm-button="false"
    show-cancel="false"
  >
  </van-dialog>
</template>

<script>
import Vue from "vue";
import { Dialog } from "vant";

// 全局注册
Vue.use(Dialog);

export default {
  name: "AutoCloseDialog",
  data() {
    return {
      message: "",
      showDialog: false,
      autoCloseTimer: null,
    };
  },
  methods: {
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    openErrorDialog(error) {
      this.message = error.message;
      this.showDialog = true;
      this.startAutoCloseTimer();
      if (error.code == 888888) {
        setTimeout(() => {
          localStorage.removeItem("token");
          if (this.$route.query.billCode) {
            this.$router.push({
              path: "/login",
              query: {
                redirect: this.$route.fullPath, // 将当前页面的完整路径作为重定向目标
                billCode: this.$route.query.billCode, // 保留 billCode 参数
              },
            });
          } else {
            this.$router.push("/login");
          }
        }, 2000);
      }else if (error.code == 100013) {
        this.$router.push("/error");
      }
    },
    openDialog(message) {
      this.message = message;
      this.showDialog = true;
      this.startAutoCloseTimer();
    },
    startAutoCloseTimer() {
      this.autoCloseTimer = setTimeout(() => {
        this.showDialog = false;
      }, 3000);
    },
    clearAutoCloseTimer() {
      if (this.autoCloseTimer) {
        clearTimeout(this.autoCloseTimer);
        this.autoCloseTimer = null;
      }
    },
  },
  beforeDestroy() {
    this.clearAutoCloseTimer();
  },
  watch: {
    // 监听 showDialog 的变化，但实际上我们主要通过 openDialog 方法来控制它
    // 如果需要，可以在这里添加额外的逻辑
  },
};
</script>

<!-- 这里不需要额外的样式，因为 Vant 的 Dialog 组件已经包含了样式 -->
<style>
/* .dialogClass{
    padding: 10px !important;
    height: ;
  } */
.van-dialog__message {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  max-height: 60vh;
  padding: 10px 20px !important;
  overflow-y: auto;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
  text-align: center;
  word-wrap: break-word;
  -webkit-overflow-scrolling: touch;
}
.van-dialog__content--isolated {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  min-height: 10px !important;
}
.van-dialog {
  position: fixed;
  top: 45%;
  left: 50%;
  width: auto !important;
  overflow: hidden;
  font-size: 16px;
  background-color: #201d1d;
  color: white;
  border-radius: 16px;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
}
</style>
