// src/router/index.js
import Vue from "vue";
import Router from "vue-router";

// 引入你的组件
import Home from "@/pages/Home/index.vue";
import LoginPage from "@/components/LoginPage/index.vue";
import Tabbar from "@/components/Tabbar/index.vue";
import Person from "@/pages/Person/index.vue";
import ChangePwd from "@/pages/ChangePwd/index.vue";
import Auth from "@/pages/Auth/index.vue";
import Setting from "@/pages/Setting/index.vue";
import LoanContent from "@/pages/LoanContent/index.vue";
import Iou from "@/pages/Iou/index.vue";
import Detail from "@/pages/Detail/index.vue";
import Contract from "@/pages/Contract/index.vue";
import WhiteList from "@/pages/WhiteList/index.vue";
import Error from "@/pages/Error/index.vue";
import AdminHome from "@/pages/AdminHome/index.vue";

Vue.use(Router);

const routes = [
  {
    path: "/tabbar",
    component: Tabbar,
    children: [
      {
        path: "home", // 注意这里的路径是相对于父路由的
        component: Home,
        meta: { requiresAuth: true },
      },
      {
        path: "adminHome", // 注意这里的路径是相对于父路由的
        component: AdminHome,
        meta: { requiresAuth: true },
      },
      {
        path: "person",
        component: Person,
        meta: { requiresAuth: true },
      },
    ],
    meta: { requiresAuth: true },
  },

  {
    path: "/loanContent",
    name: "loanContent",
    component: LoanContent,
    meta: { requiresAuth: true },
  },

  {
    path: "/whiteList",
    name: "whiteList",
    component: WhiteList,
    meta: { requiresAuth: true },
  },

  {
    path: "/detail",
    name: "detail",
    component: Detail,
    meta: { requiresAuth: true },
  },
  {
    path: "/contract",
    name: "contract",
    component: Contract,
    meta: { requiresAuth: true },
  },

  {
    path: "/changePwd",
    component: ChangePwd,
    meta: { requiresAuth: true },
  },
  {
    path: "/auth",
    component: Auth,
    meta: { requiresAuth: true },
  },
  {
    path: "/setting",
    component: Setting,
    meta: { requiresAuth: true },
  },
  {
    path: "/iou",
    component: Iou,
    meta: { requiresAuth: true },
  },
  {
    path: "/error",
    component: Error,
    meta: { requiresAuth: true },
  },
  {
    path: "/",
    redirect: localStorage.getItem("adminFlag") == 1 ? "/tabbar/adminHome" : "/tabbar/home",
  },
  {
    path: "/login",
    component: LoginPage,
    meta: { requiresAuth: false },
  },
];

const router = new Router({
  routes: routes,
});

// 全局前置守卫
// 全局前置守卫
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token"); // 从localStorage中获取token
  // 检查目标路由是否需要认证
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // 如果需要认证，但token不存在或无效
    if (!token || token === "invalid-token") {
      // 重定向到登录页面
      next({
        path: "/login",
        query: { redirect: to.fullPath }, // 可以选择将当前路由路径作为查询参数传递给登录页面，以便登录后重定向回来
      });
    } else {
      // 如果token存在且有效，允许访问
      next();
    }
  } else {
    // 如果目标路由不需要认证，允许访问
    next();
  }
});

export default router;
