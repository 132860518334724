// 对axios进行二次封装
import axios from "axios";
//import { Dialog } from "vant";
//import { Notify } from "vant";

const requests = axios.create({
  // 配置对象，基础路径
  //baseURL: "http://127.0.0.1:8888",
  baseURL: "http://8.140.59.16:8899",
  //baseURL: "http://42p17823l6.qicp.vip:49986",
  //baseURL: "https://app.jinchengzi.online/api",
  headers: {
    "Content-Type": "application/json", // 显式设置 Content-Type 为 application/json
  },
  //baseURL:'http://42p17823l6.qicp.vip',
  // 代表请求超时的时间5s
  timeout: 5000,
});

// 请求拦截器
requests.interceptors.request.use((config) => {
  return config;
});

// 响应拦截器
requests.interceptors.response.use(
  (res) => {
    // 成功的回调函数
    // if(res.data.code!=200){
    //   Dialog({ message: res.data.message });
    //   return Promise.reject(new Error('自定义错误消息'));
    // }
    return res.data;
  },
  (error) => {
    console.log(error)
    // do something with request error
    //return Promise.reject(error)
  }
);
// 对外暴露
export default requests;
