<template>
  <div>
    <!-- 确认密码弹窗 -->
    <van-action-sheet
      v-model="sheetShow"
      duration="0"
      :close-on-click-overlay="false"
      title="请输入密码"
    >
      <div style="height: 350px">
        <van-password-input
          :value="password"
          info="密码为 6 位数字"
          :error-info="errorInfo"
          :focused="sheetShow"
        />
        <van-number-keyboard
          :hide-on-click-outside="false"
          :transition="false"
          v-model="password"
          :show="sheetShow"
          @blur="sheetShow = false"
        />
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
export default {
  name: "PasswordPop",
  data() {
    return {
      sheetShow: false,
      value: "",
      errorInfo: "",
      showKeyboard: true,
      password:""
    };
  },
  watch: {
    password(password) {
      if (password.length === 6) {
        // 密码等于六位数时向父组件传递数据
        this.$emit('onPassword', password)

      } else {
        this.errorInfo = "";
      }
    },
  },

  methods:{
    isSheetShow(boolean){
      this.sheetShow = boolean;
      this.password = ""

    },
  }
};
</script>
