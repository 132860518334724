import Vue from "vue";
import App from "./App.vue";

import store from "./store";
import "vant/lib/index.css";

import { Form, tabbar, TabbarItem, Search, Cell, CellGroup } from "vant";
import { Button } from "vant";
import { Field } from "vant";
import { Icon } from "vant";
import { Checkbox } from "vant";
import VueRouter from "vue-router";
import router from "./router";
import { DatetimePicker, List, PullRefresh } from "vant";
import { Popup, Picker } from "vant";
import { SwipeCell } from "vant";
import JSEncrypt from "jsencrypt";
import { Tab, Tabs } from 'vant';
import AutoCloseDialog from './components/AutoCloseDialog.vue';  
import { Overlay } from 'vant';
import { Col, Row } from 'vant';
import { ActionSheet } from 'vant';
import PasswordPop from './components/PasswordPop.vue';  
import { NavBar } from 'vant';
import { PasswordInput, NumberKeyboard } from 'vant';


Vue.component('PasswordPop', PasswordPop);  
Vue.use(NavBar);
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(ActionSheet);
Vue.use(Col);
Vue.use(Row);
Vue.use(Overlay);
Vue.component('AutoCloseDialog', AutoCloseDialog);  
Vue.component
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(SwipeCell);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(DatetimePicker);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Form);
Vue.use(Button);
Vue.use(Field);
Vue.use(Icon);
Vue.use(Checkbox);
Vue.use(VueRouter);
Vue.use(tabbar);
Vue.use(TabbarItem);
Vue.use(Search);
Vue.use(Cell);
Vue.use(CellGroup);

Vue.config.productionTip = false;
Vue.config.devtools = true;

function getEncrypt() {
  var publicKey = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwFyYUMU3PeHsTSiJVT6WbyJucMgMAQVt
  IyNSd5nR+Ta70NE33LniM0taSC5TeG5JaR9YPdpoegOtGO4/JXZlyOVvqiprbzb6DtzVjtMQl6fI
  1fL+ppuceBU4WGyWsBhSh0URo6wxm5wVAB9JRbOKxAAJBwEWxmJUG+S8X3Ds0h2Ebjie0lO+xaEi
  MLTXte42qQtZsobFl1F5IAZdHbUPfc9Pfyggg47/AXqX+EgNI+z0nJbikGW/eZgmrBeNi9UiVrR8
  blwJIDfKWLacRG/9ILxAeZ7TrKf0TFRMu1n2C8pn89a+sm9zvLSC19MCA6k6GITbK8ojP3s80/48
  EW4mAwIDAQAB`;

  // 创建JSEncrypt实例
  var encrypt = new JSEncrypt();

  // 设置公钥
  encrypt.setPublicKey(publicKey);
  return encrypt;
}

const encrypt = getEncrypt();
Vue.prototype.$encrypt = encrypt;

new Vue({
  render: (h) => h(App),
  store,
  router,
}).$mount("#app");
