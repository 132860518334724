<template>
  <div class="loan-con">
    <auto-close-dialog ref="autoCloseDialog" />
    <PasswordPop @onPassword="obtainPassword" ref="passwordPop"></PasswordPop>
    <van-popup v-model="isShow" position="bottom" :style="{ height: '40%' }">
      <van-datetime-picker
        v-model="expirationDateParam"
        type="date"
        title="选择延期时间"
        :min-date="minDate"
        :formatter="formatter"
        @confirm="confirmExpirationDate"
        @cancel="cancelDate"
      />
    </van-popup>
    <div class="receipt-details-content">
      <div class="header">
        <img
          src="http://cx.cooolz.cn/webpage/images/loan.png"
          alt=""
          style="width: 30px; height: 30px"
        />
        <div class="label">借款内容</div>
      </div>
      <div class="label-swrap">
        <div class="label">借款人</div>
        <div class="label-value">{{ borrowerName }}</div>
      </div>
      <div class="label-swrap">
        <div class="label">出借人</div>
        <div class="label-value">{{ lenderName }}</div>
      </div>
      <div class="label-swrap">
        <div class="label">借款金额</div>
        <div class="label-value">{{ amount }}</div>
      </div>
      <div class="label-swrap">
        <div class="label">开始时间</div>
        <div class="label-value">{{ startDate }}</div>
      </div>
      <div class="label-swrap">
        <div class="label">还款时间</div>
        <div class="label-value">{{ expirationDate }}</div>
      </div>
      <div class="label-swrap" v-if="newExpirationDate && adminFlag != 1">
        <div class="label">新延期时间</div>
        <div class="label-value">{{ newExpirationDate }}</div>
      </div>
      <div class="label-swrap">
        <div class="label">借款利率</div>
        <div class="label-value">{{ annualizedInterestRate }}</div>
      </div>
      <div class="label-swrap">
        <div class="label">还款方式</div>
        <div class="label-value">{{ repaymentMethod }}</div>
      </div>
      <div class="label-swrap">
        <div class="label">借款用途</div>
        <div class="label-value">{{ usageOfLoan }}</div>
      </div>
      <!--      <div class="label-swrap">
        <div class="label">借款协议</div>
        <div class="label-value">
          <a href="http://cx.cooolz.cn/webpage/receipt/agreement?id=8421"
            >点击查看</a
          >
        </div>
      </div>-->
      <div class="label-swrap">
        <div class="label">借条状态</div>
        <div class="label-value">{{ statusStr }}</div>
      </div>
      <div style="height: 10px; background-color: rgb(246, 246, 246)"></div>
      <div class="label-swrap">
        <div class="label"></div>
        <div class="label-value" @click="goToContract(billCode)">
          <span style="color: dodgerblue">合约详情</span>
        </div>
      </div>
      <div class="label-swrap">
        <div class="label">协议编码</div>
        <div class="label-value">{{ billCode }}</div>
      </div>
      <div class="label-swrap">
        <div class="label">创建时间</div>
        <div class="label-value">{{ createTime }}</div>
      </div>
      <div v-if="status == 0 && role == 0 && adminFlag != 1" class="btn-swrap">
        <div class="btn" @click="onConfirmBill">确认</div>
        <div class="btn" @click="onRefuseBill">拒绝</div>
      </div>
      <div v-if="status == 0 && role == 1 && adminFlag != 1" class="btn-swrap">
        <div class="btn" @click="onCancelBill">取消</div>
        <div class="btn" @click="sendAgain">再次发送</div>
      </div>
      <!-- 销账待确认，只能管理员同意拒绝 -->
      <div v-if="status == 1 && role == 1 && adminFlag != 1" class="btn-swrap">
        <div class="btn" @click="onConfirmWriteOff">同意销账</div>
        <div class="btn" @click="onRefuseWriteOff">拒绝销账</div>
      </div>
      <!-- 延期待确认，发起人手机号不是我的手机号，需要展示按钮操作 -->
      <div
        v-if="
          status == 2 && promoterPhoneNumber != myPhoneNumber && adminFlag != 1
        "
        class="btn-swrap"
      >
        <div class="btn" @click="onConfirmExtensionBill">同意延期</div>
        <div class="btn" @click="onRefuseExtensionBill">拒绝延期</div>
      </div>
      <!-- 进行中，申请延期，销账 -->
      <div v-if="status == 10 && role == 0 && adminFlag != 1" class="btn-swrap">
        <div class="btn" @click="onApplyWriteOff">申请销账</div>
        <div class="btn" @click="onExtensionBill">申请延期</div>
      </div>
      <div v-if="status == 10 && role == 1 && adminFlag != 1" class="btn-swrap">
        <div class="btn" @click="onCompletedBill">销账</div>
        <div class="btn" @click="onExtensionBill">延期</div>
      </div>
    </div>

    <!-- 二维码弹窗 -->
    <van-dialog
      @opened="generateCode"
      v-model="QRCodeshow"
      show-cancel-button
      class="QRcodewarpper"
      confirm-button-text="复制链接"
      cancelButtonText="取消"
      @confirm="copyLink"
    >
      <div class="QRCode">
        <div ref="canvasWrapper"></div>
      </div>
      <div class="prompt">
        <span>保存二维码或复制链接进行分享</span>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import QRCode from "qrcode";
export default {
  name: "LoanContent",
  data() {
    return {
      QRCodeshow: false, //控制二维码弹窗的隐藏
      userName: "",
      //出借人
      lenderName: "",
      borrowerName: "",
      amount: 0,
      startDate: "",
      expirationDate: "",
      expirationDateParam: "",
      //利率
      annualizedInterestRate: 0,
      //还款方式
      repaymentMethod: "一次性还本付息",
      //借款用途
      usageOfLoan: "",
      status: 100,
      billCode: "",
      createTime: "",
      statusStr: "",
      role: "",
      text: "",
      operateStatus: 0,
      password: "",
      promoterPhoneNumber: "",
      myPhoneNumber: localStorage.getItem("phoneNumber"),
      adminFlag: localStorage.getItem("adminFlag"),
      statusParam: 0,
      isShow: false,
      minDate: new Date(),
      newExpirationDate: null,
    };
  },
  mounted() {
    // 设置二维码的URL，确保这个URL是微信能够识别的，并且指向你的Vue应用中的有效页面
    // 注意：如果你的Vue应用是部署在微信公众号内的网页，那么这里的URL应该是你在公众号后台配置的网页链接
    // 如果你的Vue应用是一个小程序，那么二维码生成和扫描的方式会有所不同，需要使用小程序相关的API
  },
  created() {
    this.init();
  },
  methods: {
    goToContract(billCode) {
      this.$router.push({
        name: "contract",
        query: {
          billCode: billCode,
        },
      });
    },
    // 格式化时间选择器中的选项文字
    formatter(type, value) {
      if (type === "year") {
        return `${value}年`;
      } else if (type === "month") {
        return `${value}月`;
      } else if (type === "day") {
        return `${value}日`;
      } else if (type === "hour") {
        return `${value}时`;
      } else if (type === "minute") {
        return `${value}分`;
      }
      return value;
    },
    // 取消延期时间
    cancelDate() {
      this.isShow = false;
      let dateString = this.expirationDate;
      let date = new Date(
        dateString.split("-")[0],
        dateString.split("-")[1] - 1,
        dateString.split("-")[2]
      );
      date.setDate(date.getDate() + 1);
      this.expirationDateParam = date;
    },
    copyLink() {
      navigator.clipboard.writeText(this.text);
    },
    // 确认账单
    onConfirmBill() {
      this.operateStatus = 1;
      this.$refs.passwordPop.isSheetShow(true);
    },
    // 拒绝账单
    onRefuseBill() {
      this.operateStatus = 2;
      this.$refs.passwordPop.isSheetShow(true);
    },
    onCancelBill() {
      this.operateStatus = 3;
      this.$refs.passwordPop.isSheetShow(true);
    },
    //同意销账
    onConfirmWriteOff() {
      this.operateStatus = 4;
      this.statusParam = 1;
      this.$refs.passwordPop.isSheetShow(true);
    },
    //拒绝销账
    onRefuseWriteOff() {
      this.operateStatus = 5;
      this.statusParam = 2;
      this.$refs.passwordPop.isSheetShow(true);
    },
    //同意延期
    onConfirmExtensionBill() {
      this.operateStatus = 6;
      this.statusParam = 1;
      this.$refs.passwordPop.isSheetShow(true);
    },
    //拒绝延期
    onRefuseExtensionBill() {
      this.operateStatus = 7;
      this.statusParam = 2;
      this.$refs.passwordPop.isSheetShow(true);
    },
    //申请销账
    onApplyWriteOff() {
      this.operateStatus = 8;
      this.$refs.passwordPop.isSheetShow(true);
    },
    onCompletedBill() {
      this.statusParam = 3;
      this.operateStatus = 10;
      this.$refs.passwordPop.isSheetShow(true);
    },
    onExtensionBill() {
      this.isShow = true;
    },
    // 确认延期时间
    confirmExpirationDate(value) {
      this.expirationDateParam = this.getDateStr(value);
      this.isShow = false;
      this.operateStatus = 9;
      this.$refs.passwordPop.isSheetShow(true); //用户填完延期时间后 显示输入密码弹框
    },
    getDateStr(value) {
      if (value instanceof Date) {
        const year = value.getFullYear();
        const month = (value.getMonth() + 1).toString().padStart(2, "0");
        const day = value.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
      }
      return "";
    },
    obtainPassword(password) {
      this.password = password;
      this.confirmBillOrRefuseBill();
      this.init();
    },

    async confirmBillOrRefuseBill() {
      const paramObj = {
        timeStamp: new Date().getTime(),
        password: this.password,
        billCode: this.billCode,
        status: this.statusParam,
      };

      let encrypted = this.$encrypt.encrypt(JSON.stringify(paramObj));

      try {
        // 如果为真调用确认接口
        if (this.operateStatus == 1) {
          await this.$store.dispatch("confirmBill", { param: encrypted });
          this.$refs.autoCloseDialog.openDialog("确认成功");
          this.$refs.passwordPop.isSheetShow(false);
          setTimeout(() => {
            this.$router.push("/tabbar/home");
          }, 1000);
        } else if (this.operateStatus == 2) {
          await this.$store.dispatch("refuseBill", { param: encrypted });
          this.$refs.autoCloseDialog.openDialog("拒绝成功");
          this.$refs.passwordPop.isSheetShow(false);
          setTimeout(() => {
            this.$router.push("/tabbar/home");
          }, 1000);
        } else if (this.operateStatus == 3) {
          await this.$store.dispatch("cancelBill", { param: encrypted });
          this.$refs.autoCloseDialog.openDialog("取消成功");
          this.$refs.passwordPop.isSheetShow(false);
          setTimeout(() => {
            this.$router.push("/tabbar/home");
          }, 1000);
        } else if (this.operateStatus == 4 || this.operateStatus == 5) {
          await this.$store.dispatch("completedBill", { param: encrypted });
          this.$refs.autoCloseDialog.openDialog("操作成功");
          this.$refs.passwordPop.isSheetShow(false);
          setTimeout(() => {
            this.$router.push("/tabbar/home");
          }, 1000);
        } else if (this.operateStatus == 6 || this.operateStatus == 7) {
          await this.$store.dispatch("confirmExtensionBill", {
            param: encrypted,
          });
          this.$refs.autoCloseDialog.openDialog("操作成功");
          this.$refs.passwordPop.isSheetShow(false);
          setTimeout(() => {
            this.$router.push("/tabbar/home");
          }, 1000);
        } else if (this.operateStatus == 8) {
          await this.$store.dispatch("applyCompletedBill", {
            param: encrypted,
          });
          this.$refs.autoCloseDialog.openDialog("操作成功");
          this.$refs.passwordPop.isSheetShow(false);
          setTimeout(() => {
            this.$router.push("/tabbar/home");
          }, 1000);
        } else if (this.operateStatus == 9) {
          this.extensionBill();
        } else if (this.operateStatus == 10) {
          await this.$store.dispatch("completedBill", { param: encrypted });
          this.$refs.autoCloseDialog.openDialog("操作成功");
          this.$refs.passwordPop.isSheetShow(false);
          setTimeout(() => {
            this.$router.push("/tabbar/home");
          }, 1000);
        }
      } catch (error) {
        this.password = "";
        this.$refs.passwordPop.isSheetShow(false);
        this.$refs.autoCloseDialog.openErrorDialog(error);
      }
    },
    async extensionBill() {
      const paramObj = {
        password: this.password,
        timeStamp: new Date().getTime(),
        billCode: this.billCode,
        expirationDate: this.expirationDateParam,
      };

      let encrypted = this.$encrypt.encrypt(JSON.stringify(paramObj));

      try {
        await this.$store.dispatch("extensionBill", {
          param: encrypted,
        });
        this.$refs.autoCloseDialog.openDialog("延期信息提交成功");
        this.$refs.passwordPop.isSheetShow(false);
        setTimeout(() => {
          this.$router.push("/tabbar/home");
        }, 1000);
      } catch (error) {
        this.$refs.autoCloseDialog.openErrorDialog(error);
      }
    },
    sendAgain() {
      this.QRCodeshow = true;
    },
    generateCode() {
      QRCode.toCanvas(
        this.text,
        {
          width: 200,
          margin: 1,
        },
        (error, canvas) => {
          if (error) {
            console.error(error);
            return;
          }
          // 在canvas的父元素中插入canvas元素
          this.$refs.canvasWrapper.innerHTML = "";
          this.$refs.canvasWrapper.appendChild(canvas);
        }
      );
    },
    getStatusHtml(status, expirationDate) {
      if (status === 0) {
        return "待确认";
      } else if (status === 1) {
        return "销账待确认";
      } else if (status === 2) {
        return "延期待确认";
      } else if (status === 10) {
        const now = new Date();
        // 设置明天凌晨 0 点的时间
        const tomorrowMidnight = new Date(now);
        tomorrowMidnight.setHours(0, 0, 0, 0); // 将时间设置为凌晨 0 点
        const expirationDateTime =  new Date(expirationDate);
        expirationDateTime.setHours(0, 0, 0, 0); // 将时间设置为凌晨 0 点
        if (tomorrowMidnight.getTime() <= expirationDateTime.getTime()) {
          return "进行中";
        }
        expirationDateTime.setDate(expirationDateTime.getDate() + 1);
        if (tomorrowMidnight.getTime() === expirationDateTime.getTime()) {
          return "宽限期";
        }
        return "已逾期";
      } else if (status === 20) {
        return "已完成";
      } else if (status === 30) {
        return "被拒绝";
      }
      return "未知状态";
    },
    async init() {
      const param = {
        billCode: this.$route.query.billCode,
        // billCode: '1850802340875206656'
      };
      try {
        const result = await this.$store.dispatch("getBillInfoByCode", param);
        if (!result.data) {
          this.$router.push("/error");
        }
        const role = localStorage.getItem("role");
        this.role = role;
        this.borrowerName = result.data.borrowerName;
        this.lenderName = result.data.lenderName;
        // if (role == 0) {
        //   this.userName = localStorage.getItem("userName");
        //   this.lenderName = result.data.userName;
        // } else {
        //   this.lenderName = localStorage.getItem("userName");
        //   this.userName = result.data.userName;
        // }
        this.amount = result.data.amount;
        this.status = result.data.status;
        //0:待确认 1:销账待确认 2:延期待确认  10:进行中 20:已完成 30:拒绝 :40取消
        this.statusStr = this.getStatusHtml(result.data.status, result.data.expirationDate);
        this.annualizedInterestRate = result.data.annualizedInterestRate;
        this.createTime = result.data.createTime;
        this.expirationDate = result.data.expirationDate;
        this.newExpirationDate = result.data.newExpirationDate;
        this.billCode = result.data.billCode;
        this.text =
          this.$store.state.login.domainName +
          "/dist/#/loanContent?billCode=" +
          this.billCode;
        //this.repaymentMethod = result.data.repaymentMethod;
        this.startDate = result.data.startDate;
        this.promoterPhoneNumber = result.data.promoterPhoneNumber;

        let dateString = result.data.expirationDate;
        let date = new Date(
          dateString.split("-")[0],
          dateString.split("-")[1] - 1,
          dateString.split("-")[2]
        );
        date.setDate(date.getDate() + 1);
        this.minDate = date;
        if (result.data.usageOfLoan == 1) {
          this.usageOfLoan = "线下借款";
        } else {
          this.usageOfLoan = "其它";
        }
      } catch (error) {
        this.$refs.autoCloseDialog.openErrorDialog(error);
      }
    },
  },
};
</script>
<style scoped lang="less">
@import url("index.less");

.QRcodewarpper {
  background-color: white;

  .QRCode {
    width: 300px !important;
    height: 300px;
    padding: 10px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .prompt {
    color: black;
    display: flex;
    justify-content: center;
    margin: 20px;
  }
}
</style>
