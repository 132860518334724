import {
  reqLogin,
  sendVerificationCode,
  authentication,
  updatePassword,
  updateProfilePicture,
  systemLogout,
} from "@/api";
import MyCustomError from "../MyCustomError.js";
const state = {
  //domainName : "http://8.140.59.16"
  domainName : "https://app.jinchengzi.online"
};

const mutations = {};

const actions = {
  // 发送登录请求
  async login(c, data) {
    let result = await reqLogin(data);
    if (result.code == 200) {
      localStorage.setItem("token", result.data.token);
      localStorage.setItem("adminFlag", result.data.adminFlag);
      localStorage.setItem("phoneNumber", result.data.phoneNumber);
      localStorage.setItem("role", result.data.role);
      if (result.data.profilePicture) {
        localStorage.setItem("profilePicture", result.data.profilePicture);
      } else {
        localStorage.removeItem("profilePicture");
      }
      if (result.data.userName) {
        localStorage.setItem("userName", result.data.userName);
      } else {
        localStorage.removeItem("userName");
      }
      if (result.data.idCard) {
        localStorage.setItem("idCard", result.data.idCard);
      } else {
        localStorage.removeItem("idCard");
      }
      return result.data;
    } else {
      // 登录失败，抛出错误
      return Promise.reject(new MyCustomError(result.message, result.code));
    }
  },
  async sendVerificationCode(c, data) {
    const result = await sendVerificationCode(data);
    if (result.code == 200) {
      return result;
    } else {
      return Promise.reject(new MyCustomError(result.message, result.code));
    }
  },
  async authentication(c, data) {
    const result = await authentication(data);
    if (result.code == 200) {
      return result;
    } else {
      return Promise.reject(new MyCustomError(result.message, result.code));
    }
  },
  async updatePassword(c, data) {
    const result = await updatePassword(data);
    if (result.code == 200) {
      return result;
    } else {
      return Promise.reject(new MyCustomError(result.message, result.code));
    }
  },

  // 修改头像
  async updateProfilePicture(c, data) {
    const result = await updateProfilePicture(data);
    if (result.code == 200) {
      return result;
    } else {
      return Promise.reject(new MyCustomError(result.message, result.code));
    }
  },

  async systemLogout(c, data) {
    const result = await systemLogout(data);
    if (result.code == 200) {
      return result;
    } else {
      return Promise.reject(new MyCustomError(result.message, result.code));
    }
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
