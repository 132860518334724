import { getWhiteList,addWhiteList,deleteWhiteList } from "@/api";
import MyCustomError from "../MyCustomError.js";
const state = {
  count: 0,
};

const mutations = {};

const actions = {
  async getWhiteList(c, data) {
    const result = await getWhiteList(data);
    if (result.code == 200) {
      return result;
    } else {
      return Promise.reject(new MyCustomError(result.message, result.code));
    }
  },

  async addWhiteList(c, data) {
    const result = await addWhiteList(data);
    if (result.code == 200) {
      return result;
    } else {
      return Promise.reject(new MyCustomError(result.message, result.code));
    }
  },

  async deleteWhiteList(c, data) {
    const result = await deleteWhiteList(data);
    if (result.code == 200) {
      return result;
    } else {
      return Promise.reject(new MyCustomError(result.message, result.code));
    }
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
