<template>
  <div>
    <auto-close-dialog ref="autoCloseDialog"/>
    <van-form
        @submit="onSubmit"
        input-align="right"
        error-message-align="right"
    >
      <van-field
          v-model="username"
          name="借款人"
          label="借款人"
          placeholder="请输入借款人姓名"
          :rules="[{ required: true, message: '请输入借款人姓名' }]"
      />

      <van-field
          v-model="phoneNumber"
          type="number"
          name="借款人手机号"
          label="借款人手机号"
          placeholder="请输入借款人手机号"
          :rules="[{ pattern: regex1, message: '请输入正确借款人手机号' }]"
      />

      <van-field
          v-model="amount"
          type="number"
          name="借款金额"
          label="借款金额"
          placeholder="请输入借款金额"
          :rules="[{ pattern: regex2, message: '请输入整数金额' }]"
      />

      <!-- 借款日期 -->
      <van-field
          v-model="startDate"
          :readonly="true"
          name="借款日期"
          label="借款日期"
          @click="showPopup"
          placeholder="点击选择借款日期"
          :rules="[{ required: true, message: '点击选择借款日期' }]"
      />
      <van-popup v-model="show" position="bottom" :style="{ height: '40%' }">
        <van-datetime-picker
            v-model="currentDate"
            type="date"
            title="选择年月日"
            :min-date="minDate"
            :formatter="formatter"
            @confirm="confirmDate"
            @cancel="cancelDate"
        />
      </van-popup>

      <!-- 还款日期 -->
      <van-field
          v-model="expirationDate"
          :readonly="true"
          name="还款日期"
          label="还款日期"
          @click="showRepaymentDateModel"
          placeholder="点击选择还款日期"
          :rules="[{ required: true, message: '点击选择还款日期' }]"
      />
      <van-popup
          v-model="repaymentDateShow"
          position="bottom"
          :style="{ height: '40%' }"
      >
        <van-datetime-picker
            v-model="currentDate"
            type="date"
            title="选择年月日"
            :min-date="expirationDateMinDate"
            :formatter="formatter"
            @confirm="confirmRepaymentDate"
            @cancel="cancelDate"
        />
      </van-popup>

      <van-field
          v-model="annualizedInterestRate"
          type="number"
          name="年化利率"
          label="年化利率"
          placeholder="请输入年化利率"
          :rules="[{ pattern: regex2, message: '请输入整数年化利率' }]"
      />

      <!-- 借款用途 -->
      <van-field
          readonly
          clickable
          name="usageOfLoan"
          :value="usageOfLoanText"
          label="借款用途"
          placeholder=""
          @click="usageOfLoanShow = true"
      />
      <van-popup v-model="usageOfLoanShow" position="bottom">
        <van-picker
            show-toolbar
            :columns="columns"
            @confirm="usageOfLoanConfirm"
            @cancel="usageOfLoanShow = false"
        />
      </van-popup>

      <!-- 还款方式 -->
      <van-field
          v-model="repaymentMethod"
          :readonly="true"
          name="还款方式"
          label="还款方式"
          placeholder="一次性还本付息"
      />

      <!-- 提交按钮 -->
      <div style="margin: 16px">
        <van-button
            round
            block
            class="btn"
            @click="onSubmit"
            native-type="submit"
        >提交
        </van-button
        >
      </div>

      <PasswordPop @onPassword="obtainPassword" ref="passwordPop"></PasswordPop>


    </van-form>


    <!-- 二维码弹窗 -->
    <van-dialog
        @opened="generateCode"
        v-model="QRCodeshow"
        show-cancel-button
        class="QRcodewarpper"
        confirm-button-text="复制链接"
        cancelButtonText="取消"
        @confirm="copyLink"
    >
      <div class="QRCode">
        <div ref="canvasWrapper"></div>
      </div>
      <div class="prompt">
        <span>保存二维码或复制链接进行分享</span>
      </div>

    </van-dialog>
  </div>
</template>

<script>
import QRCode from 'qrcode'

export default {
  name: "Iou",
  data() {
    return {
      QRCodeshow: false, //控制二维码弹窗的隐藏

      password: "",
      regex1: /^[1][3-9]\d{9}$/,
      regex2: /^(?:0|[1-9]\d*)$/,
      username: "",
      phoneNumber: "",
      amount: "",
      annualizedInterestRate: "",
      show: false,
      repaymentDateShow: false,
      currentDate: new Date(),
      minDate: new Date(),
      expirationDateMinDate: new Date(),
      startDate: "",
      expirationDate: "",
      usageOfLoan: "1",
      usageOfLoanShow: false,
      usageOfLoanText: "",
      columns: [
        {value: 1, text: "线下借款"},
        {value: 2, text: "其它"},
      ],
      repaymentMethod: "一次性还本付息",
      text: ""
    };
  },

  created() {
    this.checkUserName();
    this.getExpirationDateMinDate();
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.text);
    },
    getExpirationDateMinDate() {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      this.expirationDateMinDate = tomorrow;
    },
    generateCode() {
      QRCode.toCanvas(this.text, {
        width: 200,
        margin: 1
      }, (error, canvas) => {
        if (error) {
          console.error(error)
          return
        }
        // 在canvas的父元素中插入canvas元素
        this.$refs.canvasWrapper.innerHTML = "";
        this.$refs.canvasWrapper.appendChild(canvas);
      })
    },
    obtainPassword(password) {
      this.password = password
      this.onPassword()
    },

    async onPassword() {

      // 整理数据调用接口
      const {
        username,
        phoneNumber,
        amount,
        startDate,
        password,
        expirationDate,
        annualizedInterestRate,
        usageOfLoan,
      } = this;
      const paramObj = {
        timeStamp: new Date().getTime(),
        password,
      };

      let encrypted = this.$encrypt.encrypt(JSON.stringify(paramObj));

      const param = {
        borrowerNumber: phoneNumber,
        borrowerName: username,
        amount,
        annualizedInterestRate,
        usageOfLoan: JSON.stringify(usageOfLoan),
        repaymentMethod: "1",
        expirationDate,
        startDate,
        cipherText: encrypted,
      };
      try {
        const result = await this.$store.dispatch("createBil", param);
        this.$refs.autoCloseDialog.openDialog("提交成功");
        this.$refs.passwordPop.isSheetShow(false);
        this.text = this.$store.state.login.domainName + "/dist/#/loanContent?billCode=" + result.data;
        this.QRCodeshow = true;
        // setTimeout(() => {
        //   this.generateCode();
        // }, 1000);
      } catch (error) {
        this.password = "";
        this.$refs.passwordPop.isSheetShow(false);
        this.$refs.autoCloseDialog.openErrorDialog(error);
        //this.$refs.passwordPop.isSheetShow(false)

      }
    },

    onSubmit() {
      const {
        username,
        phoneNumber,
        amount,
        startDate,
        expirationDate,
        columns,
        annualizedInterestRate,
      } = this;
      if (
          username &&
          phoneNumber &&
          amount &&
          startDate &&
          expirationDate &&
          columns &&
          annualizedInterestRate
      ) {
        if (!this.regex2.test(amount)) {
          this.$refs.autoCloseDialog.openDialog("金额必须是整数");
          return;
        }
        if (!this.regex2.test(annualizedInterestRate)) {
          this.$refs.autoCloseDialog.openDialog("年化利率必须是整数");
          return;
        }

        let expirationDate1 = new Date(expirationDate);
        let startDate1 = new Date(startDate);

        if (expirationDate1 > startDate1) {
          // 截止日期大于开始日期时
          this.$refs.passwordPop.isSheetShow(true)
          // this.sheetShow = true;
        } else {
          this.$refs.autoCloseDialog.openDialog("还款日期必须大于借款日期");
        }
      }
    },

    checkUserName() {
      if (!localStorage.getItem("userName")) {
        this.$router.push("/auth");
      }
    },
    nowDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0"); // 月份从0开始，需要加1，并且用padStart补全两位数字
      const day = String(now.getDate()).padStart(2, "0"); // 用padStart补全两位数字
      this.startDate = `${year}-${month}-${day}`;
    },
    onConfirm(time) {
      this.value = time;
    },
    showPopup() {
      this.show = true;
    },
    showRepaymentDateModel() {
      this.repaymentDateShow = true;
    },
    // 格式化时间选择器中的选项文字
    formatter(type, value) {
      if (type === "year") {
        return `${value}年`;
      } else if (type === "month") {
        return `${value}月`;
      } else if (type === "day") {
        return `${value}日`;
      } else if (type === "hour") {
        return `${value}时`;
      } else if (type === "minute") {
        return `${value}分`;
      }
      return value;
    },

    // 处理确认事件，获取选中时间
    confirmDate(value) {
      // 格式化选中时间并存储到 selectedDate
      this.startDate = this.getDateStr(value);
      this.show = false;
      this.currentDate = new Date();
    },
    confirmRepaymentDate(value) {
      this.expirationDate = this.getDateStr(value);
      this.repaymentDateShow = false;
      this.currentDate = new Date();
    },
    getDateStr(value) {
      if (value instanceof Date) {
        const year = value.getFullYear();
        const month = (value.getMonth() + 1).toString().padStart(2, "0");
        const day = value.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
      }
      return "";
    },
    // 处理取消事件
    cancelDate() {
      this.repaymentDateShow = false;
      this.show = false;
      this.currentDate = new Date(); // 重置当前时间（可选）
    },
    usageOfLoanConfirm(value) {
      this.usageOfLoanText = value.text;
      this.usageOfLoan = value.value;
      this.usageOfLoanShow = false;
    },
  },
  mounted() {
    // 在组件挂载后，可以调用 methods 中的方法
    this.nowDate();
    this.getDateStr();
    this.usageOfLoanText = this.columns[0].text;
    this.usageOfLoan = this.columns[0].value;
  },
};
</script>
<style scoped lang="less">
@import url("@/assets/Less/index.less");

.btn {
  background: @background;
  border: none;
}

.QRcodewarpper {
  background-color: white;

  .QRCode {
    width: 300px !important;
    height: 300px;
    padding: 10px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .prompt {
    color: black;
    display: flex;
    justify-content: center;
    margin: 20px;

  }
}
</style>
