var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-con"},[_c('div',{staticClass:"auth-form"},[_c('auto-close-dialog',{ref:"autoCloseDialog"}),_c('van-form',[_c('van-cell-group',{attrs:{"inset":""}},[_c('van-field',{attrs:{"label":"手机号","readonly":true},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('div',{staticClass:"input-swraps"},[_c('van-field',{attrs:{"label":"验证码","type":"number","name":"VerificationCode","placeholder":"请输入验证码","rules":[{ pattern: _vm.regex2, message: '请输入正确的验证码' }]},model:{value:(_vm.VerificationCode),callback:function ($$v) {_vm.VerificationCode=$$v},expression:"VerificationCode"}}),_c('div',{staticStyle:{"margin-right":"1rem"}},[_c('van-button',{staticClass:"codeBtn",attrs:{"native-type":"button","disabled":_vm.isCountdownActive},on:{"click":_vm.SendVerificationCode}},[(_vm.isShow === true)?_c('span',[_vm._v("获取验证码")]):_c('span',[_vm._v(_vm._s(_vm.countdownTime)+"秒")])])],1)],1),_c('van-field',{attrs:{"type":"password","name":"password","label":"交易密码","placeholder":"请输入6位交易密码","rules":[{ pattern: _vm.regex2, message: '请输入正确交易密码' }]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('van-field',{attrs:{"type":"password","name":"确认交易密码","label":"确认交易密码","placeholder":"请再次输入交易密码","rules":[
            { pattern: _vm.regex2, message: '请输入正确交易密码' },
            {
              validator: _vm.confirmPasswordValid,
              required: true,
              message: '密码不一致，请重试',
            },
          ]},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1),_c('div',{staticStyle:{"margin":"16px"}},[_c('van-button',{staticClass:"btn",attrs:{"round":"","block":""},on:{"click":_vm.updatePassword}},[_vm._v(" 提交 ")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }