//  对接口统一管理

import request from "./request";

//发送get请求
// export const reqList = ()=>requests({url:'/',method:'get'})

//发送验证码
export const sendVerificationCode = (data) =>
  request({
    url: `/system/sendVerificationCode`,
    method: "post",
    data,
  });

// 发送登录请求
export const reqLogin = (data) =>
  request({
    url: `/system/login`,
    method: "post",
    data,
  });

// 发送登录请求
export const getBillList = (data) =>
  request({
    url: `/bill/getBillList`,
    method: "post",
    data,
    headers: {
      token: localStorage.getItem("token"),
    },
  });

export const getBillAmountSum = (data) =>
    request({
        url: `/bill/getBillAmountSum`,
        method: "post",
        data,
        headers: {
            token: localStorage.getItem("token"),
        },
    });

export const getBillInfoByCode = (data) =>
  request({
    url: `/bill/getBillInfoByCode`,
    method: "post",
    data,
    headers: {
      token: localStorage.getItem("token"),
    },
  });

export const getBillContract = (data) =>
  request({
    url: `/bill/getBillContract`,
    method: "post",
    data,
    headers: {
      token: localStorage.getItem("token"),
    },
  });

export const authentication = (data) =>
  request({
    url: `/user/authentication`,
    method: "post",
    data,
    headers: {
      token: localStorage.getItem("token"),
    },
  });

export const updatePassword = (data) =>
  request({
    url: `/user/updatePassword`,
    method: "post",
    data,
    headers: {
      token: localStorage.getItem("token"),
    },
  });

export const createBil = (data) =>
  request({
    url: `/bill/createBill`,
    method: "post",
    data,
    headers: {
      token: localStorage.getItem("token"),
    },
  });

export const updateProfilePicture = (data) =>
  request({
    url: `/user/updateProfilePicture`,
    method: "post",
    data,
    headers: {
      token: localStorage.getItem("token"),
    },
  });

export const systemLogout = (data) =>
  request({
    url: `/system/logout`,
    method: "post",
    data,
    headers: {
      token: localStorage.getItem("token"),
    },
  });

// 白名单
export const getWhiteList = (data) =>
  request({
    url: `/whiteList/getWhiteList`,
    method: "post",
    data,
    headers: {
      token: localStorage.getItem("token"),
    },
  });

export const addWhiteList = (data) =>
  request({
    url: `/whiteList/addWhiteList`,
    method: "post",
    data,
    headers: {
      token: localStorage.getItem("token"),
    },
  });

export const deleteWhiteList = (data) =>
  request({
    url: `/whiteList/deleteWhiteList`,
    method: "post",
    data,
    headers: {
      token: localStorage.getItem("token"),
    },
  });

export const deleteBill = (data) =>
  request({
    url: `/bill/deleteBill`,
    method: "post",
    data,
    headers: {
      token: localStorage.getItem("token"),
    },
  });

// 用户确认账单接口
export const confirmBill = (data) =>
  request({
    url: `/bill/confirmBill`,
    method: "post",
    data,
    headers: {
      token: localStorage.getItem("token"),
    },
  });

// 用户拒绝账单
export const refuseBill = (data) =>
  request({
    url: `/bill/refuseBill`,
    method: "post",
    data,
    headers: {
      token: localStorage.getItem("token"),
    },
  });

export const cancelBill = (data) =>
  request({
    url: `/bill/cancelBill`,
    method: "post",
    data,
    headers: {
      token: localStorage.getItem("token"),
    },
  });

export const completedBill = (data) =>
  request({
    url: `/bill/completedBill`,
    method: "post",
    data,
    headers: {
      token: localStorage.getItem("token"),
    },
  });
//操作延期账单
export const confirmExtensionBill = (data) =>
  request({
    url: `/bill/confirmExtensionBill`,
    method: "post",
    data,
    headers: {
      token: localStorage.getItem("token"),
    },
  });
//申请销账
export const applyCompletedBill = (data) =>
  request({
    url: `/bill/applyCompletedBill`,
    method: "post",
    data,
    headers: {
      token: localStorage.getItem("token"),
    },
  });
export const extensionBill = (data) =>
  request({
    url: `/bill/extensionBill `,
    method: "post",
    data,
    headers: {
      token: localStorage.getItem("token"),
    },
  });
